
.no-data-placeholder {
	position:absolute;
	top:4.75em;
	width:100%;
	font-weight:bold;
	font-size:1.25em;
	opacity:0;
	z-index:-1;
}

.no-data-placeholder.xl {
	top:6.5em;
}

.no-data-placeholder.xs {
	top:3.75em;
}

.no-data-placeholder.shown {
	opacity:1;
	z-index:10;
}

.comparison-placeholder-no-data.shown {
	display: flex;
	min-height: 5em;
	max-height: 5em;
	height: 5em;
	position: relative;
	top: 1.8em;
	justify-content: center;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}
