.BoxHardware-root {
	display:flex;
	flex-grow:1;
	min-height:2.5em;
	padding: 0.5em 0.5em;
	flex-direction: column;
}

.BoxHardware-root .container{
	/*min-height:25.5em;*/
}

.BoxHardware-root .container .item{
	padding: 0.5em 0.5em;
}

.BoxHardware-root .properties-table{
	display:flex;
	flex-grow:1;
	flex-direction: column;
	box-shadow: 0em 0em 0.2em 0.1em rgba(0, 0, 0, .2);
	border:solid 1px rgba(0, 0, 0, .5);
}

.BoxHardware-root.dark .properties-table{
	box-shadow: 0em 0em 0.2em 0.1em rgba(255, 255, 255, .2);
	border-color:rgba(255, 255, 255, .5);
}

.BoxHardware-root.dark .properties-table .box{
	display:flex;
	flex-grow:1;
	flex-direction: column;
}

.BoxHardware-root .properties-table .header{
	padding: 0.25em 0.5em;
	background-color:rgba(0, 0, 0, .2);
}

.BoxHardware-root.dark .properties-table .header{
	background-color:rgba(255, 255, 255, 0.25);
}

.BoxHardware-root .properties-table .properties-line{
	display:flex;
	flex-grow:1;
	flex-direction: row;
}

.BoxHardware-root .properties-table .properties-line .label{
	padding: 0.25em 1em;
	width:50%;
	text-align:left;
	border-top:solid 1px rgba(0, 0, 0, .5);
	border-right:solid 1px rgba(0, 0, 0, .5);
	font-size:0.9em;
}

.BoxHardware-root.dark .properties-table .properties-line .label , 
.BoxHardware-root.dark .properties-table .properties-line .value {
	border-color:rgba(255, 255, 255, .5);
}

.BoxHardware-root .properties-table .properties-line .value{
	padding: 0.25em 1em;
	width:50%;
	border-top:solid 1px rgba(0, 0, 0, .5);
	font-size:0.9em;
	justify-content:center;
	align-items:center;
	display:flex;
}

.BoxHardware-root .properties-table .properties-line .value.small{
	font-size:0.65em;
}
