
.dark {
	color:#FFFFFF!important;
	/*background-color: #21252c!important;/*#424242;*/
}

.Period-chooser-Popup.dark {
	background-color: #21252c!important;
}

.Period-chooser{
	display: inline-block;
    top: -0.15em;
    position: relative;
}

.Period-chooser .label-period {
	display:inline-block;
	text-align:right;
	margin-left:0.5em;
}

.Period-chooser.time-chooser {
	width: 100%;
}

.Period-chooser.time-chooser .MuiTextField-root {
	width: 100%;
}

.dark .Period-chooser .Icon {
	color:#FFFFFF!important;
}

.Period-chooser-Popup {
	display:flex;
	flex-direction:column;
	transform-origin: 0 0;
}

.Period-chooser-Popup .Item {
	clear:both;
	display:block;
	min-width:11em;
    letter-spacing: 0.75px;
	text-transform: var(--text-transform);
	font-size: var(--amplify-text-sm);
    font-family: var(--amplify-font-family);
    font-weight: 600;
	padding:1rem;
	text-transform: uppercase;
	text-align:left;
}

.dark .MuiPickersModal-dialogRoot .MuiTypography-body1,
.dark .MuiPickersModal-dialogRoot .MuiButton-label {
	color:#FFFFFF!important;
}

.period-line.start {
    display: flex;
	margin-bottom: 1.5em;
}

.period-line.end {
    display: flex;
}

.period-line .MuiFormControl-root.MuiTextField-root {
    flex-grow: 1;
}

.quick-access-buttons {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-evenly;
	margin: 1em 0;
}

.quick-access-buttons .btn {
	display: flex;
	flex-direction: row;
	padding: 0.5em 0.75em;
	justify-content: center;
	align-items: center;
	border: solid 1px rgba( 255 , 255 , 255 , 0.75 );
	cursor: pointer;
	text-align: center;
}

.quick-access-buttons .btn.selection-button.selected {
    background-color: #1bc943;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 500px) { 
	
}
