.select-command {
    width: 100%;
    margin-bottom: 0.75em !important;
}

.data-sending , .init-parameters {
    display: flex;
    flex-grow: 1;
    width: 100%;
    flex-direction: column;
}
