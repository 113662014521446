.HopperData-root{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	overflow:hidden;
	padding:0.25em 0.5em;
}

.HopperData-root.compact{
	cursor:pointer;
	border: solid 1px;
	border-radius:0.5em;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.HopperData-root.compact .identity{
	display:flex;
	flex-direction:row;
	flex-grow:1;
	width: 100%;
}

.HopperData-root.compact .identity .position {
	position: relative;
    left: 0em;
    background: linear-gradient(135deg, #3b3e66 0%, #3b3e66 49.9%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
	color: white;
    width: 3.5em;
    height: 3.5em;
    margin-top: -0.75em;
    margin-left: -0.75em;
    align-self: center;
    justify-self: center;
    /* padding-top: 0.25em; */
    padding-left: -0.5em;
}

.HopperData-root.compact .identity .position .number {
	position: relative;
    margin-top: 0.45em;
    margin-left: -0.75em;
	font-weight:bold;
}

.Alternative .HopperData-root.compact .identity .position {
	
	background: linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49.9%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
	color: #424242;
}

.HopperData-root.compact .identity .value {
	display:flex;
	flex-direction:row;
	font-size:1.25em;
	flex-grow:1;
	width:100%;
	margin-left: -1.75em;
	align-self:center;
	justify-self:center;
	justify-content:center;
}

.HopperData-root.compact .data {
	display:flex;
	flex-direction:column;
	width:100%;
	flex-grow:1;
	align-items:center;
	justify-content:center;
}

.HopperData-root.compact .data .line {
	display:flex;
	flex-direction:row;
	width:100%;
	flex-grow:1;
	/*margin: 0.15em 0em;*/
}

.HopperData-root.compact .data .line .icon {
	display:flex;
	align-items:center;
	justify-content:center;
}

.HopperData-root.compact .data .line .icon .main{
	position:absolute;
}

.HopperData-root.compact .data .line .icon .decorator{
	position:absolute;
	margin-left:2.75em;
	margin-top:1.5em;
}

.HopperData-root.compact .data .line .value {
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
}

.HopperData-root.detailed{
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
}

.HopperData-root.detailed .hopper-value {
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:1.75em;
	font-weight:bold;
}

.HopperData-root.detailed .hopper-details {
	display:flex;
	flex-grow:1;
}
