.infinity {
    font-size: 20px;
}

.actions .selection-button.selected {
    background-color: #1bc943;
}

.actions .selection-button {
    margin-left: 0.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.license-count-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.datetime-block {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
}

.small-space {
    width: 0.25em;
}

.line.header , .line.data {
    display: flex;
    flex-direction: row;
    width: 80em;
    flex-grow: 1;
    justify-content: center;
}

.line .column {
    display: flex;
    justify-content: center;
}

.line .column.value {
    min-width: 10em;
}

.line .column.at {
    min-width: 10em;
}

.line .column.label {
    min-width: 20em;
}
