.search {
    /*max-width:650px;
    min-width:650px;*/
}

.dark {
    color:#FFFFFF!important;
}

.dark .Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.45) !important;
}

.dark .available-choices .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75) !important;
}

.mode-toggle.button {
    cursor: pointer;
    display: flex;
    align-self: flex-end;
    font-size: 0.55em;
}

.results-global-count {
    position:absolute;
    right:1em;
    margin-top:-1.5em;
    font-size:0.75em;
}

.cm-search-menu {
    min-width:50em;
    max-width:50em;
    /*min-height:40em;*/
    /*max-height:40em;*/
    display:flex;
    flex-direction: column;
    transition: all 0.3s ease;
}

.cm-search-menu .title{
    flex-grow:1;
    min-width:100%;
    text-align:center;
    font-size:1.5em;
    border-bottom: solid 1px;
    margin-bottom: 0.5em;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm-search-menu .title span {
    display: flex;
    flex-grow:1;
    align-items: center;
    justify-content: center;
}

.cm-search-menu .filter-row {
    display: flex;
    flex-grow:1;
    margin-bottom: 0.5em;
    min-width:100%;
    flex-direction: row;
}

.filter-editing-mode {
    display: flex;
    flex-grow:1;
    margin-bottom: 0.5em;
    min-width:100%;
    flex-direction: column;
    justify-content: center;
}

.filter-header {
    display: flex;
    flex-grow:1;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    min-width:100%;
    flex-direction: row;
}

.filter-header .name {
    display: flex;
    flex-grow:1;
}

.user-actions {
    display: flex;
    flex-grow:1;
    flex-direction: row;
    min-width:100%;
}

.filter-header .name .MuiTextField-root {
    display: flex;
    flex-grow:1;
    min-width:100%;
}

.dark .filter-header .name .Mui-disabled {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.72);
}

.filter-selected-count {
    margin-left: 0.5em;
    margin-right: 0.5em;
    min-width: 5em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5em;
}

.cm-search-menu .filter-selector {
    display: flex;
    flex-grow:1;
    margin-right: 0.25em;
}

.reminder-filter {
    display: flex;
    flex-direction: row;
}

.filter-name {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.cm-search-menu .filter-row .button,
.filter-editing-mode .button {
    border: 1px solid rgba( 0 , 0 , 0 , 0.55 );
    color: rgba( 0 , 0 , 0 , 0.85 );
    font-weight: bold;
    border-radius: 0.1em;
    font-size: 0.95em;
    margin: 0.25em;
    cursor: pointer;
    padding: 0.25em 0.75em;
    align-items: center;
    justify-content: center;
    display: flex;
}

.user-actions .button {
    flex-grow:1;
}

.dark .cm-search-menu .filter-row .button,
.dark .filter-editing-mode .button {
    border: 1px solid rgba( 255 , 255 , 255 , 0.55 );
    color: rgba( 255 , 255 , 255 , 0.85 );
}

.cm-search-menu .filter-row .button.disabled ,
.filter-editing-mode .button.disabled  {
    border: 1px solid rgba( 0 , 0 , 0 , 0.25 );
    color: rgba( 0 , 0 , 0 , 0.45 );
    cursor: default;
}

.dark .cm-search-menu .filter-row .button.disabled ,
.dark .filter-editing-mode .button.disabled  {
    border: 1px solid rgba( 255 , 255 , 255 , 0.25 );
    color: rgba( 255 , 255 , 255 , 0.45 );
    cursor: default;
}

.simple-mode-filters {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    margin-bottom: 0.25em;
    max-height:25em;
    overflow-y:auto;
}

.selectors {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.selection {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-radius: 0.25em;
    padding: 1em 0.5em;
    border: 1px solid rgba( 0 , 0 , 0 , 0.55 );
    margin-left: 0.5em;
    height: fit-content;
}

.combo-container {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
}

.combo-block {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-top: 0.75em;
    margin-bottom: 2.5em;
}

.combo-label {
    display: flex;
    align-self: flex-start;
    justify-self: flex-start;
    position:relative;
    font-size: 0.75em;
    margin-top: -5.85em;
    margin-left: 0.5em;
    padding: 0.5em;
    background-color: #FFFFFF;
}

.dark .combo-label {
    background-color: #424242;
}

.cm-search-menu .combo-container .filter-selector {
    margin-right: 0;
}

.cm-search-menu .combo-container > :first-child {
    margin-right: 0.5em;
}

.box-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 1em 0.5em;
    border-radius: 0.25em;
    border: 1px solid rgba( 0 , 0 , 0 , 0.55 );
    margin-top: 0.5em;
}

.dark .box-container, .dark .selection {
    border: 1px solid rgba( 255 , 255 , 255 , 0.55 );
}

.filter-selector {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
}

.section-title {
    display: flex;
    width: 100%;
    flex-direction: row;
    border-bottom: 1px solid transparent;
    align-items: center;
    justify-content: center;
}

.separator {
    display: flex;
    flex-direction: row;
    height: 0.1em;
    background: linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 60%, transparent 100%);
    background: -webkit-linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 60%, transparent 100%);
    background: -moz-linear-gradient(90deg,rgba(0,0,0,0) 0%, rgba(0,0,0,1) 30%, rgba(0,0,0,1) 60%, transparent 100%);
    margin-bottom: 0.75em;
}

.dark .separator {
    background: linear-gradient(90deg,rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 60%, transparent 100%);
    background: -webkit-linear-gradient(90deg,rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 60%, transparent 100%);
    background: -moz-linear-gradient(90deg,rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 60%, transparent 100%);
}

.content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.content .line-payStation {
    padding: 0.25em;
    border-radius: 0.25em;
    margin-bottom: 0.25em;
}

.content .line-payStation:hover, .MuiSimpleTreeView-root .item-content:hover {
    background-color: rgba( 27 , 201 , 67 , 0.5 );
}

.content .line-payStation.selected, .MuiSimpleTreeView-root .selected {
    background-color: #1bc943;
}

.section-title span{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.quick-trash {
    display: flex;
    justify-self: flex-end;
    cursor: pointer;
    margin-left: 0.75em;
}

.cm-search-menu .fields{
    min-width:100%;
}

.cm-search-menu .filters{
    justify-content: space-evenly;
    display: flex;
    margin: 0.25em;
}

.cm-search-menu .fields .MuiTextField-root{
    margin-top:1em;
    width:100%;
}

.dark .cm-search-menu .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.dark .cm-search-menu .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.dark .cm-search-menu .MuiOutlinedInput-root:active .MuiOutlinedInput-notchedOutline,
.dark .cm-search-menu .MuiOutlinedInput-root:enabled .MuiOutlinedInput-notchedOutline,
.dark .cm-search-menu .MuiOutlinedInput-root:valid .MuiOutlinedInput-notchedOutline,
.dark .cm-search-menu .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline
{
    border-color:#FFFFFF!important;
}

.dark .cm-search-menu .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    color:#FFFFFF!important;
}

.dark .cm-search-menu .MuiInputBase-input.MuiOutlinedInput-input {
    color:#FFFFFF!important;
}

.dark .cm-search-menu .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    color:#FFFFFF!important;
}

.cm-search-menu .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.cm-search-menu .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
.cm-search-menu .Mui-focused.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border-color:#3b3e66;
}

.cm-search-menu .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    color:#3b3e66!important;
}

.cm-search-menu .results{
    margin-top:0.5em;
    text-align:center;
    width:100%;
}

.on-right .MuiBadge-anchorOriginTopRightRectangle {
    transform: scale(1) translate(150%, -20%);
}

.search-list {
    min-height:calc( 30em - 8px );
    height:calc( 30em - 8px );
    max-height:calc( 30em - 8px );
    z-index:1;
    overflow-y:auto;
    padding-top:0!important;
    margin-bottom:0.65em!important;
    margin-right:0.25em!important;
}

.dark .search-list .MuiListItem-button {
    border-color: rgba( 255 , 255 , 255 , 0.15 );
}

.search-icon {
    color:#FFFFFF!important;
}

.search-list .MuiListItem-button {
    border-bottom: solid 1px rgba( 0 , 0 , 0 , 0.25 );
}

.chip-orange.MuiChip-root, .chip-orange.MuiChip-root:active, .chip-orange.MuiChip-root:focus {
    background-color:#e39600;
    border-color:#e39600;
}

body .MuiChip-root, body .MuiChip-root:active, body .MuiChip-root:focus {
    color:rgba( 0 , 0 , 0 , 1 )!important;
}

body .dark .MuiChip-root, .dark .MuiChip-root:active, .dark .MuiChip-root:focus {
    color:rgba( 255 , 255 , 255 , 1 )!important;
}

.chip-green.MuiChip-root, .chip-green.MuiChip-root:active, .chip-green.MuiChip-root:focus {
    background-color:#c63232 ;
    border-color:#c63232 ;
}

.chip-blue.MuiChip-root, .chip-blue.MuiChip-root:active, .chip-blue.MuiChip-root:focus {
    background-color:#3570b3;
    border-color:#3570b3;
}

.chip-yellow.MuiChip-root, .chip-yellow.MuiChip-root:active, .chip-yellow.MuiChip-root:focus {
    background-color:#5f31c7 ;
    border-color:#5f31c7 ;
}

.chip-purple.MuiChip-root, .chip-purple.MuiChip-root:active, .chip-purple.MuiChip-root:focus {
    background-color:purple;
    border-color:purple;
}

body .MuiChip-root.MuiChip-outlined {
    background-color:transparent!important;
}

body .MuiButtonBase-root:not(.MuiListItem-button):not(.MuiTab-root):not(:disabled):not(.MuiCheckbox-root):not(.MuiSwitch-switchBase):hover {
    transform: translateY(0px)!important;
}

.line-payStation .extra-info {
    margin-top:0.25em;
    font-style: italic;
    font-size: 0.80em;
    opacity: 0.65;
}

.box-display {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba( 255,255,255,0.5 );
    padding-bottom: 0.25em;
    margin-bottom: 0.25em;
}

.displayed.extra-info {
    clear:both;
    margin-top:0.25em;
    font-style: italic;
    font-size: 0.60em;
    opacity: 0.65;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) {

}

@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) {

}

@media (max-width: 500px) {
    .cm-search-menu {
        min-width:28em!important;
        max-width:28em!important;
        min-height:40em!important;
    }
}
