.elapsed-time-indicator-root{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.elapsed-time-indicator-root .icon{
	
}

.elapsed-time-indicator-root .details{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}

.elapsed-time-indicator-root .details .label{
	font-size: 0.90em;
}

.elapsed-time-indicator-root .details .value{
	font-size: 1.25em;
}