.MuiDrawer-root {
    z-index: 1;
}

.Root.Dashboard .MuiDrawer-root .MuiPaper-root.drawer-paper {
    position: fixed;
    white-space: nowrap;
    width: 265px;
    height: 100%;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.Root.Dashboard .MuiDrawer-root .MuiPaper-root.drawer-paper.IOS {

}

.Root.Dashboard .MuiDrawer-root.desktop {
    min-width: 265px;
}

.Root.Dashboard .MuiDrawer-root.desktop.close {
    min-width: 56px;
}

.Root.Dashboard .MuiDrawer-root .MuiPaper-root.drawer-paper.close {
    overflow-x: hidden;
    width: 56px;
}

.toolbarIcon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
}

.Root.Dashboard .MuiDrawer-root .MuiPaper-root {
    padding-top: 24px;
    min-width: 56px;
}

.Root.Dashboard .MuiDrawer-root {
    padding-top: 24px;
    min-width: 60px;
    transition: min-width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    z-index: 100;
}

.Root.Dashboard.Alternative .MuiDrawer-root , .Root.Dashboard.Alternative .MuiDrawer-root .MuiPaper-root {
    background-color: #424242;
}

.Root.Dashboard.Alternative .MuiDrawer-root .MuiPaper-root .MuiListItemButton-root.Mui-selected,
.Root.Dashboard.Alternative .MuiDrawer-root .MuiPaper-root .MuiListItemButton-root.Mui-selected:hover {
    background-color: rgba(255, 255, 255, 0.16);
}


.MuiDrawer-root .subList , .MuiDrawer-root .MuiList-root {
    overflow-x: hidden;
}

.MuiDrawer-root.desktop .subList,
.MuiDrawer-root.desktop .MuiList-root {
    overflow: hidden;
    max-height: 60em;
}
