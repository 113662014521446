.MuiTableCell-body {
    border: 1px solid rgba(224, 224, 224, 1);
    line-break: anywhere;
}

.MuiTableCell-body.inventory-timestamp {
    max-width: 150px;
}

.MuiTableCell-body.box-name {
    max-width: 100px;
}

.MuiTableCell-body.detail-value,
.MuiTableCell-body.detail-qty,
.MuiTableCell-body.total {
    text-align:right!important;
}

.MuiTableCell-root {
    text-align:center!important;
}

.comparison-table .MuiTableCell-root{
    text-align:right!important;
}

.MuiTableCell-root.MuiTableCell-body.cell.hour {
    font-size:0.85em;
}

.MuiTableCell-root.MuiTableCell-body.cell.amount {
    font-size:0.85em;
}

.MuiTableCell-root {
    padding: 16px 4px;
}

.MuiTableCell-root.MuiTableCell-head.header.date:not(.ope) {
    flex-grow: 0!important;
    width: 15em;
}

.MuiTableCell-root.MuiTableCell-head.header.cbms {
    flex-grow: 0!important;
    text-align: center!important;
    width: 15em;
}

.MuiTableCell-root.MuiTableCell-body.cell.cbms {
    text-align: left!important;
    padding-left:2em;
}

.MuiTableCell-root.MuiTableCell-head.header.text {
    text-align: center!important;
}

.MuiTableCell-root.MuiTableCell-body.cell.text {
    text-align: left!important;
    padding-left:2em;
}

.MuiTableCell-root.MuiTableCell-head.header.error, .MuiTableCell-root.MuiTableCell-head.header.manual {
    min-width:6vh;
    width:6vh;
    max-width:6vh;
}

.MuiTableCell-root.MuiTableCell-head.header.date {
    text-align: right!important;
    /*padding-left: 4em;*/
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .type-label {
    text-align: left;
    padding-left: 5em;
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .opt-data-label {
    text-align: left;
    padding-left: 8em;
}

.MuiTableRow-root {
    border: none;
}

.MuiTableCell-root {
    border: none;
}

.hidden-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    transition: max-height 0.3s ease;
    overflow: hidden;
    max-height: 100em;
}

.hidden-content.closed {
    max-height: 0;
}

.detail-row {
    font-size: 0.8em;
    display: flex;
    justify-content: space-evenly;
    padding: 0.25em 0.5em;
    width: 100%;
}

.detail-column {
    flex: 1;
    text-align: center;
}

.header-row {
    font-weight: bold;
}

.no-border, .no-border .cell {
    border: none!important;
    box-shadow: none!important;
}

.user-group-count {
    margin-left: 0.5em;
}

.MuiTableRow-root, .MuiToolbar-root {
    display: flex !important;
    width: 100%;
}

.MuiTableCell-root , .MuiTableCell-footer {
    display: flex !important;
    flex-grow: 1 !important;
}

.group-by-container {
    display: flex ;
    flex-direction: column;
}

.group-by-spacer {
    display: flex ;
    flex-grow: 1 ;
}

.cell.group-by {
    max-width: 100%;
}

@media (max-width: 768px) {
    .detail-row {
        flex-direction: row;
        align-items: flex-start;
    }

    .detail-column {
        width: 100%;
        text-align: left;
        font-size: 0.75em;
    }

    .cell.group-by {
        padding-left: 0.5em !important;
    }

    .cell.group-by .toggle.icon {
        left: 0;
    }

    .cell.group-by .group-by-container {
        flex-grow: 1;
        min-width: 8em;
    }

    .cell.group-by .group-by-container .type-label {
        padding-left: 0.5em !important;
        left: 0;
        font-size: 0.65em;
        flex-grow: 0;
    }

    .cell.group-by .group-by-container .opt-data-label {
        padding-left: 0.5em !important;
        left: 0;
        font-size: 0.45em;
        flex-grow: 0;
    }

    .group-by-spacer {
        min-width: 0.75em;
        flex-grow: 0;
    }

    .header.sale {
        padding-left: 0;
        padding-right: 0;
    }

    .sub-total-groupBy span.small.left-large {
        font-size: 0.4em;
        display:none;
    }

    .sub-total-groupBy span.right-price {
        font-size: 0.8em;
    }

    .MuiTablePagination-toolbar {
        padding-left: 5px !important;
        font-size: 0.8em;
    }

    .MuiTablePagination-select {
        padding-left: 4px!important;
        padding-right: 22px!important;
    }

    .MuiTablePagination-selectLabel {
        font-size: 0.80em !important;
    }

    .MuiTableFooter-root .MuiTableRow-footer:empty {
        max-width: 0;
    }

    .detail-column.header.opt {
        text-align: center;
    }

    .card-box.reminder {
        font-size: 0.9em !important;
        margin-left: 0.25em;
    }

    .type-reminder {
        padding: 0!important;
    }

    .total-reminder-content {
        padding: 0!important;
    }

    #exportable-reminder {
        padding: 1em!important;
    }

    .tab-panel-content {
        padding-top: 0.25em!important;
    }

    .MuiTableFooter-root {
        flex-direction: column;
    }

    .comparison-table .cell.type {
        max-width: 150px!important;
        min-width: 120px!important;
    }

    .MuiTableHead-root .sale-number span ,
    .MuiTableHead-root .sale span ,
    .MuiTableHead-root .type span{
        font-size: 0.75em;
        text-align: center;
    }

    .totalCashIn {
        min-width: 90px;
    }

    .totalNbCashIn {
        min-width: 60px;
    }

    .type-label {
        font-size: 0.75em;
    }

    .comparison-table .cell.amount.operationAmountPeriod1,
    .comparison-table .cell.amount.operationAmountPeriod2,
    .comparison-table .cell.amount.gapAmountPeriod {
        padding: 16px 0 16px 0;
    }

    .cell.hour {
        max-width: 50px!important;
    }

    .MuiTableFooter-root .operationAmountPeriod1 ,
    .MuiTableFooter-root .operationAmountPeriod2 ,
    .MuiTableFooter-root .gapAmountPeriod {
        font-size: 0.85em!important;
    }

    .MuiTablePagination-root {
        overflow: hidden !important;
    }

    body .MuiTabs-root .MuiTabs-flexContainer {
        /*flex-direction: column;*/
    }

    #exportable.message #exportable-table {
        /*margin-top:5em;*/
    }

    .MuiTabs-scrollableY .MuiTabs-indicator {
        width: 100%!important;
    }

    .MuiTabs-vertical {
        height: 125px!important;
    }
}
