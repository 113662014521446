.cm-total-inventory {
	margin-bottom: 1em!important;
	margin-top: 0.5em!important;
	overflow: visible!important;
}

.cm-total-inventory .MuiPaper-root {
	overflow: visible!important;
}

.block-total-data {
	margin-right:2em;
}

.cm-graph .apexcharts-tooltip {
	margin-top:5.5em;
}

.Root.Dashboard.Alternative .card-tr-actions .badge{
	background-color:transparent!important;
}

.Root.Dashboard.Alternative .block-total-data {
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .MuiTypography-root {
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .MuiPaper-elevation1 {
    box-shadow: 0px 2px 1px -1px rgba(255,255,255,0.2), 0px 1px 1px 0px rgba(255,255,255,0.14), 0px 1px 3px 0px rgba(255,255,255,0.12);
}

.Root.Dashboard.Alternative .MuiPaper-elevation3 {
    box-shadow: 0px 3px 3px -2px rgba(255,255,255,0.2), 0px 3px 4px 0px rgba(255,255,255,0.14), 0px 1px 8px 0px rgba(255,255,255,0.12);
}

.Root.Dashboard.Alternative .card-box.card-box-border-bottom {
    border-top-color: rgba(255, 255, 255, 0.125) !important;
    border-right-color: rgba(255, 255, 255, 0.125) !important;
    border-left-color: rgba(255, 255, 255, 0.125) !important;
    border-bottom-width: 4px;
}

.Root.Dashboard.Alternative .apexcharts-tooltip {
	background-color:#808080;
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .apexcharts-tooltip-title {
	background-color:#545454;
}
/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.block-total-data {
		margin-right:0.5em;
	}
	
	.Root.Dashboard.Alternative .MuiGrid-grid-xs-12 .card-box-total-cm::after{
		background-color: rgba(255, 255, 255, 0.25);
	}
}


@media (max-width: 400px) { 
	
}