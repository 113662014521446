.cm-graph-xl {
	justify-content:center;
}

.cm-graph-xl .apexcharts-yaxis {
	display:none;
}

.cm-graph-xl .apexcharts-inner.apexcharts-graphical {
	/*transform: translate(0, 0);*/
}

.cm-graph-xl .cm-chart {
	padding:0.25em;
}

.cm-graph-xl .apexcharts-tooltip {
	top:-10em!important;
}

.grid-menu .MuiGrid-item.cm-btn-left {
	border-right:rgba( 0 , 0 , 0 , 0.5 ) solid 1px!important;
}

.Root.Dashboard.Alternative .apexcharts-legend-text{
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .apexcharts-tooltip {
	background-color:#808080;
}

.Root.Dashboard.Alternative .apexcharts-tooltip-title {
	background-color:#545454;
}

.Root.Dashboard.Alternative .MuiButton-label {
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .grid-menu .MuiGrid-item {
	border-bottom: none!important;
}

.grid-menu .MuiGrid-item {
	border:none!important;
}

.dark .grid-menu .MuiGrid-item .MuiButtonBase-root:hover {
	background-color:rgba( 255 , 255 , 255 , 0.125 )!important;
}

.dark .grid-menu .MuiGrid-item .MuiButtonBase-root {
	color:#FFFFFF!important;
}

.grid-menu .MuiGrid-item .MuiButtonBase-root {
	color:#3b3e66;
	cursor:pointer;
}

.dark .grid-menu .MuiGrid-item.cm-btn-left {
	border-right:rgba( 255 , 255 , 255 , 0.5 ) solid 1px!important;
}

.total-header {
	display:inline-flex;
	font-size:1em!important;
	
}

.period{
	clear:both;
	display:block;
	margin:auto;
	font-size:0.75em!important;
	padding: 0 0.25em;
}

.progress-container{
	justify-content:center;
}

.h2.d-block.mb-2.mx-auto.mt-1 {
	opacity:0.5;
}

.h2.d-block.mb-2.mx-auto.mt-1.selected {
	opacity:1;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.display-4.font-weight-bold.total-header {
		font-size:1em!important;
	}
}

@media (min-width: 400px) and (max-width: 960px) { 
	.prct-progress-sale{
		clear:both;
		display:block;
	}
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 450px) { 
	.height-line-bottom {
		/*max-height: 41em!important;
		min-height: 41em!important;
		height: 41em!important;*/
	}
	
	.makeStyles-container-15 {
		/*padding-bottom: 0!important;*/
	}
	
	.grid-menu .MuiGrid-item.cm-btn-left {
		border-right:none!important;
		border-bottom:rgba( 0 , 0 , 0 , 0.5 ) solid 1px!important;
	}
	
	.dark .grid-menu .MuiGrid-item.cm-btn-left {
		border-right:none!important;
		border-bottom:rgba( 255 , 255 , 255 , 0.5 ) solid 1px!important;
	}
	
	.apexcharts-tooltip.apexcharts-active {
		left:35px!important;
	}
}