.tab-panel-reminder {
    cursor: pointer;
}

.MuiTabs-root.hidden {
    display: none;
}

.Alternative .tab-panel-reminder.active .card-box.reminder.operation-reminder .type-reminder {
    background-color: #2a2e33;
    transition: background-color 0.3s ease;
}

.tab-panel-reminder.active .card-box.reminder.operation-reminder .type-reminder {
    background-color: #2a2e33;
    color: white;
    transition: background-color 0.3s ease;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}
