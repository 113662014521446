:root{
  /*--amplify-primary-color: transparent;
  --amplify-primary-tint: rgba(255 , 255, 255, 0.125);
  --amplify-primary-shade: rgba(255 , 255, 255, 0.25);*/
  --amplify-font-family: 'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  --amplify-text-xxs: 0.75rem;
  --amplify-text-xs: 0.81rem;
  --amplify-text-sm: 0.875rem;
  --amplify-text-md: 1rem;
  --amplify-text-md-sub: 1.15rem;
  --amplify-text-lg: 1.5rem;
  --amplify-text-xl: 2rem;
  --amplify-text-xxl: 2.5rem;
  --amplify-primary-color: #ff9900;
  --amplify-primary-contrast: var(--amplify-white);
  --amplify-primary-tint: #ffac31;
  --amplify-primary-shade: #e88b01;
  --amplify-secondary-color: #152939;
  --amplify-secondary-contrast: var(--amplify-white);
  --amplify-secondary-tint: #31465f;
  --amplify-secondary-shade: #1F2A37;
  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: var(--amplify-white);
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537BE5;
  --amplify-background-color: var(--amplify-white);
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #ffffff;
  --amplify-smoke-white: #f5f5f5;
  --amplify-red: #dd3f5b;
  --amplify-blue: #099ac8;
  --footer-height: 2.5em;
  --cashmonitor-transition-delay: 1s;
  --cashmonitor-col-size: 12%;
  --cashmonitor-row-size: 22%;
  --cashmonitor-col-margin: 1rem;
  --cashmonitor-col-padding: 0.25em;
  --cashmonitor-grid-gap: 0.5%;
  --header-height: 24px;
  --cashmonitor-money-bg-color: #F5F5F5;
  --cashmonitor-money-hover-bg-color: var(--amplify-primary-color);
  --cashmonitor-identity-bg-color: #F5F5F5;
  --cashmonitor-identity-hover-bg-color: var(--amplify-primary-color);
  --linear-gauge-width: 1.5em;
}

body {
  overscroll-behavior: none;
}

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 8vh;
  pointer-events: none;
}

.App-header {
  background-color: var(--amplify-white);
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom:6vh;
  box-shadow: 0 0.4vmin 0.2vmin -0.2vmin rgba(0,0,0,0.25);
}

.App-footer {
  bottom: 0vh;
  position: absolute;
  width: 100%;
  background-color: var(--amplify-primary-color);
  color: var(--amplify-white);
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  height: var(--footer-height);
  vertical-align: middle;
  line-height: 2.5em;
  z-index:30;
}

.App-footer:after {
  position: absolute;
  bottom: var(--footer-height);
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: linear-gradient(to top, rgba(255,255,255, 1) 30%, rgba(255,255,255, 0) 70% );
  pointer-events: none;
}

.tooltip-anchor {
  display:inline-block;
}

amplify-authenticator {
  margin-top:5em;
}
