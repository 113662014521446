.cm-dialog-node {
	display:flex;
	min-width:450px;
}

.cm-dialog-node .header-dialog-node {
	display:flex;
	flex-grow:1;
	min-height:30px;
	width:100%;
	margin: 0.75em 0;
	min-width: 455px;
}

.cm-dialog-node-move .header-dialog-node {
	display:flex;
	flex-grow:1;
	min-height:30px;
	width:100%;
	min-width: 455px;
}

#node-edit-name{
	font-size:0.78em;
}

#node-edit-reseller-uuid{
	font-size:0.92em;
}

.remove-img-btn {
	position:absolute;
	margin-top:-1.5em;
	margin-left:13em;
	cursor:pointer;
}

.cm-dialog-node .header-dialog-node .MuiFormControl-root {
	width:100%;
}

.cm-dialog-node .header-dialog-node .icon-role, .cm-dialog-node-move .header-dialog-node .icon-role {
	display:flex;
	margin-right:0.5em;
}

.cm-dialog-node .header-dialog-node .label-name, .cm-dialog-node-move .header-dialog-node .label-name{
	display:flex;
	flex-grow:1;
	justify-content: center;
    align-items: center;
	font-size:1.25em;
}

.cm-dialog-node .body-node, .cm-dialog-node-move .body-node {
	display:flex;
	flex-direction:column;
	flex-grow:1;
	min-height:2em;
	min-width: 455px;
}

.cm-dialog-node .MuiOutlinedInput-input {
	padding: 0.5em 1em;
}

.cm-dialog-node .MuiInputLabel-outlined {
	transform: translate(0.75em, 0.75em) scale(1);
}

.cm-dialog-node-move .body-node .title {
	font-size:1.15em;
	margin:0.75em 0;
}

.cm-dialog-node-move .body-node .search {
	margin-bottom:0.5em;
}

.cm-dialog-node .body-node .upload-container {
	display:flex;
	flex-direction:row;
	flex-grow:1;
	margin: 0.75em 0;
}

.cm-dialog-node .body-node .upload-container .thumb {
	max-height:125px;
	margin-right:0.5em;
}

.cm-dialog-node .body-node .upload-container .thumb.full-width {
	max-height:200px;
	margin: auto;
}

.cm-dialog-node .body-node .upload-container .thumb.hidden {
	display:none;
}

.cm-dialog-node .body-node .country{
	width:100%;
}

.cm-dialog-node .body-node .country .CountrySelector-root{
	padding: 0 0 0.75em 0;
}

.cm-dialog-node .body-node .coordinates-field, .cm-dialog-node .body-node .address-field{
	margin: 0.75em 0;
}

.cm-dialog-node .body-node .coordinates-field .MuiFormControl-root, .cm-dialog-node .body-node .address-field .MuiFormControl-root {
	width:100%;
}

.cm-dialog-node .body-node .role-type {
	display:flex;
	flex-grow:1;
	font-size:0.95em;
	justify-content: center;
    align-items: center;
	padding-bottom:1.5em;
}

.cm-dialog-node .action-bar-dialog-node {
	display:flex;
	flex-grow:1;
	align-self:end;
}

.cm-dialog-node .action-bar-dialog-node button{
	width:100%;
}
