.main-lines {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.installation-state-monitoring {}

.display {
    margin-top: 2.25em!important;
}

.technical-alert {
    height: fit-content;
}

.installation-display {}
