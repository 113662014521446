.license-indicator {
    position: fixed;
    bottom: 5em;
    /*right: 5em;*/
    left: 1em;
    z-index: 1000;
    max-width: 15em;
}

@keyframes bump-license {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.1); /* Slightly larger */
    }
    50% {
        transform: scale(1.25); /* Peak bump */
    }
    70% {
        transform: scale(1.1);  /* Slightly larger */
    }
    100% {
        transform: scale(1);    /* Back to normal */
    }
}

.license-indicator .icon-license svg{
    display: inline-block;
    font-size: 25px;
    animation: bump-license 0.5s ease-in-out infinite;
    margin-bottom: 0.5em;
}
