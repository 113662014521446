#modal-loader {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	background-color: #21252c;
	color:#ffffff;
	display:none;
	z-index:-1;
}

#modal-loader.loading {
	display:block;
	z-index:15001;
}

#loading-indicator {
	margin-top:25%;
	display:flex;
	justify-content: center;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 500px) { 

}
