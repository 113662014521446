.inventoryDate {
	font-size: 0.75em;
}

.small-cm {
	max-width:50px!important;
	max-height:50px!important;
}

.pulse-animation.slow {
    transition: all 0.5s ease-in-out;
    transform: scale(1.1);
    animation: pulse 2s infinite;
}

.flip-card-cm {
	transition: all 0.5s ease-in-out;
	cursor:pointer;
}

.border-success .flip-card-cm:hover {
	color:#1bc943 !important;
}

.border-danger .flip-card-cm:hover {
	color:#f83245 !important;
}

.border-info .flip-card-cm:hover {
	color:#11c5db !important;
}

.extra-mt {
	margin-top:4vh!important;
}

#exportable-graphic {
	padding-left: 0.5em;
}

#exportable-graphic .MuiPaper-root.no-shadow {
	border-color: transparent!important;
	box-shadow: none;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.MuiGrid-grid-xs-12 .card-box-total-cm {
		margin-top:1.2em;
		margin-bottom:1.4em;
	}

	.MuiGrid-grid-xs-12 .card-box-total-cm::after {
		content: " ";
		position: relative;
		width: 90%;
		margin: 0 auto;
		top: 0.8em;
		height: 0.2em;
		display: block;
		background-color: rgba(0, 0, 0, 0.25);
	}
	
	.display-4.font-weight-bold {
		font-size:1.4em!important;
	}
	
	.block-total-data .display-4.font-weight-bold {
		font-size:1.2em!important;
	}
	
	.Root.Dashboard .block-total-data {
		max-width: 7em!important;
		font-size:0.9em!important;
	}
	
	.Root.Dashboard .block-total-data .display-4.font-weight-bold {
		font-size:0.9em!important;
		margin-left:0.25em!important;
		margin-right:0.25em!important;
	}
	
	.Root.Dashboard .block-total-data .cm-graph {
		padding-left: 0.25rem !important;
		padding-right: 0.25rem !important;
	}
	
	.font-size-lg {
		font-size:1rem!important;
	}

	.card-content-overlay.pb-4 {
		padding-bottom: 0!important;
	}

	.card-content-overlay .mt-4.mb-1 {
		margin-top: 0.25em!important;
		margin-bottom: 0!important;
	}

	.tab-panel-content .cm-total-inventory {
		margin-top: 0!important;
		margin-bottom: 0!important;
	}

	.tab-panel-content .cm-total-inventory .pt-3{
		padding-top: 0!important;
	}

	.tab-panel-content .cm-total-inventory .block-total-data {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.tab-panel-content .cm-total-inventory .block-total-data .mb-2 {
		margin-bottom: 0!important;
	}

	#exportable-reminder {
		padding: 0!important;
	}
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	.cm-data-box {
		min-width:100%!important;
		max-width:100%!important;
	}

	.card-box-total-cm {
		margin-top:1.2em;
		margin-bottom:1.4em;
	}
	
	.makeStyles-totalCard-30 {
		margin:auto!important;
		margin-top:1.2em!important;
		margin-bottom:1.4em!important;
		min-width:50%!important;
		max-width:50%!important;
	}

	.card-box-total-cm::after {
		content: " ";
		position: relative;
		width: 90%;
		margin: 0 auto;
		top: 0.8em;
		height: 0.2em;
		display: block;
		background-color: rgba(0, 0, 0, 0.25);
	}

	.Alternative .card-box-total-cm::after {
		background-color: rgba(255, 255, 255, 0.25);
	}
	
	.display-4.font-weight-bold {
		font-size:1.4em!important;
	}
	
	.font-size-lg {
		font-size:1rem!important;
	}
	
	.Root.Dashboard.Opened {
		
	}
	
	.Root.Dashboard.Opened {
		
	}
}
