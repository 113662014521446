.Account-root {
	
}

.Account-root .content {
	padding:0.25em;
	justify-content:center;
	display:flex;
	flex-direction:column;
}

.Account-root .content .container {
	border:solid 1px rgba( 0 , 0 , 0 , 0.15 );
	border-radius:1em;
	padding:0.25em;
	margin-top:0.75em;
}

.Alternative .Account-root .content .container {
	border-color:rgba( 255 , 255 , 255 , 0.15 );
}

.Account-root .content .container .title {
	position:absolute;
	left:2em;
	margin-top:-1em;
	padding:0 0.5em;
	background-color:#FFFFFF;
	text-decoration:underline;
	font-size:1.10em;
}

.Alternative .Account-root .content .container .title {
	background-color:#21252c;
}

.Account-root .content .container .form {
	padding-top: 0.5em;
}

.Account-root .content .container .form .line{
	display:flex;
	flex-direction:row;
	padding:0.25em;
	align-self:center;
	flex-grow:1;
}

.Account-root .content .container .form .line .label {
	flex-grow:1;
	text-align:left;
	text-transform:uppercase;
	letter-spacing: .07272727em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .6875rem;
	line-height: 1rem;
	opacity:0.8;
}

.Account-root .content .container .form .line .value {
	text-align:left;
	padding-left:0.25em;
	font-weight:bold;
	align-self:end;
	min-width:22.5em;
}

.Account-root .content .container .form .line .value .MuiFormControl-root {
	width: 100%;
}

.Account-root .card-box .card-header.expandable{
	cursor:pointer;
}

.verification-bagdge .state {
	text-transform:none;
	font-style:italic;
}

.Account-root .content .container .current-device-online {
	display:flex;
	flex-direction:row;
}

.Account-root .content .container .current-device-online .layer{
	flex-grow:1;
}

.Account-root .content .container .current-device-online .layer .value.inline-display {
	display:flex;
	flex-direction:row;
}

.Account-root .content .container .current-device-online .layer .value.inline-display .data-block{
	margin-right:0.5em;
}

.Account-root .content .container .current-device-online .layer .value.inline-display .data-block.small{
	font-style: italic;
	padding-top:0.2em;
	font-size:0.9em;
}

.cm-dialog.card-box .MuiDialog-paper .current-device-online {
	display:flex;
	flex-direction:row;
}

.cm-dialog.card-box .MuiDialog-paper .current-device-online .layer{
	flex-grow:1;
}

.cm-dialog.card-box .MuiDialog-paper .current-device-online .layer .value.inline-display {
	display:flex;
	flex-direction:row;
}

.cm-dialog.card-box .MuiDialog-paper .current-device-online .layer .value.inline-display .data-block{
	margin-right:0.5em;
}

.cm-dialog.card-box .MuiDialog-paper .current-device-online .layer .value.inline-display .data-block.small{
	font-style: italic;
	padding-top:0.2em;
	font-size:0.9em;
}

.cm-dialog.card-box .MuiDialog-paper .line{
	padding: 0.5em 1em;
	display:flex;
	flex-direction:row;
	padding:0.25em;
	align-self:center;
	flex-grow:1;
}

.cm-dialog.card-box .MuiDialog-paper .line .label {
	flex-grow:1;
	text-align:left;
	text-transform:uppercase;
	letter-spacing: .07272727em;
    font-family: Roboto,Arial,sans-serif;
    font-size: .6875rem;
	line-height: 1rem;
	opacity:0.8;
	padding-left:1em;
}

.cm-dialog.card-box .MuiDialog-paper .line .value {
	text-align:left;
	padding-left:0.25em;
	font-weight:bold;
	align-self:end;
	min-width:10em;
}

.Account-root .content .container .other-device-online {}

.Account-root .content .container .other-device-online .table {
	display:flex;
	flex-direction:row;
}

.Account-root .content .container .other-device-online .btn-details {}

.Account-root .content .container .other-device-online .table .column {
	flex-grow:1;
}

.Account-root .content .container .other-device-online .table .column .cell{}

.Alternative .Account-root .content .container .other-device-online .table .column .cell{
	color:#FFFFFF;
}

.Account-root .content .container .other-device-online .table .column .cell.icon{
	font-size:1.5em;
}

.Account-root .content .container .other-device-online .table .column .cell.value{
	font-weight:bold;
	font-size:1.25em;
}

.cm-dialog.card-box .MuiDialog-paper{
	min-width:40em;
	min-height:30em;
}

.cm-dialog.card-box.dark .MuiDialog-paper{
	color:#FFFFFF!important;
	display:flex;
	padding:0!important;
	border-radius: 0.75rem;
}

.cm-dialog.card-box .card-header {
	justify-content:center;
	padding:0.25em 0.5em;
	text-align:center;
	margin-bottom:0.25em;
}

.cm-dialog.card-box.dark .card-header {
	background-color:rgba(255, 255, 255, 0.25)!important;
}

.cm-dialog.card-box .MuiDialog-paper .device-list {
	max-height: 30em;
    height: fit-content;
	
}

.cm-dialog.card-box .MuiDialog-paper .device-list .MuiListItemIcon-root {
	min-width: 25px;
}

.cm-dialog.card-box .MuiDialog-paper .No-device {
	display:flex;
	flex-grow:1;
	justify-content:center;
	font-size:1.25em;
	line-height:10em;
	vertical-align:middle;
}

.Account-root .scheduler {
	display:flex;
	flex-grow:1;
	justify-content:center;
	flex-direction:column;
	padding:1em;
}

.Account-root .scheduler .No-Data {
	font-size:1.25em;
}

.Account-root .scheduler .task-list .task-line {
	
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}

@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 450px) {

	.cm-dialog.card-box .MuiDialog-paper{
		min-width:25em;
		min-height:30em;
	}

	.cm-dialog.card-box .MuiDialog-paper .line .value {
		min-width:9em;
		max-width:9em;
	}

	.cm-dialog.card-box .MuiDialog-paper .line .browser-full-version {
		display:none;
	}
	
	.Account-root .content .container .form .line .value {
		min-width:15em;
	}
}
