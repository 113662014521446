
.dark, .dark .MuiButton-textPrimary {
	color:#FFFFFF!important;
}

.MuiPopover-paper {

}

.Menu-CashMonitor {
	
}

.Menu-CashMonitor .Icon {
	color:#FFFFFF!important;
}

.Menu-CashMonitor.User {
	
}

.Menu-CashMonitor.User .Icon {
	
}

.cm-dialog-pdf-generation .Menu-CashMonitor.Action.dark{
	background-color: #424242!important;
}

.Menu-CashMonitor.dark,
.Menu-CashMonitor-Popup.dark {
	background-color: #424242!important;
}

.MuiToolbar-root .Menu-CashMonitor.Action.dark {
	background-color: transparent!important;
}

.Menu-CashMonitor.Action {

}

.Menu-CashMonitor.Action .Icon {
	
}

.Menu-CashMonitor-Popup {
	display:flex;
	flex-direction:column;
	transform-origin: 0 0;
	position: relative;
	z-index:15001 !important;
}

.MuiPaper-root.dark {
	background-color: #424242!important;
	color:#FFFFFF!important;
}

.Menu-CashMonitor-Popup .Item {
	clear:both;
	display:block;
	min-width:9em;
    /*letter-spacing: 0.75px;
	text-transform: var(--text-transform);*/
	/*font-size: var(--amplify-text-sm);
    font-family: var(--amplify-font-family);
    font-weight: 600;*/
	padding:0.5rem;
	font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
	text-transform: none;
	/*text-transform: uppercase;*/
	text-align:left;
}

.Menu-CashMonitor-Popup .Item.MuiButton-root.Mui-disabled {
	background:transparent;
	color:rgba( 0 , 0 , 0 , 0.25 );
}

.dark.Menu-CashMonitor-Popup .Item.MuiButton-root.Mui-disabled {
	color:rgba( 255 , 255 , 255 , 0.25 );
}

.cm-search-menu {
	display:flex;
	flex-direction: column;
}

.cm-dialog-pdf-generation .Item {
	clear:both;
	display:block;
	min-width:15em;
    /*letter-spacing: 0.75px;
	text-transform: var(--text-transform);
	font-size: var(--amplify-text-sm);
    font-family: var(--amplify-font-family);
    font-weight: 600;
	padding:1rem;
	text-transform: uppercase;*/
	padding:0.5rem;
	font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
	text-transform: none;
	text-align:left;
}

.Menu-CashMonitor.Action.dark  .card-header {
	background-color: rgba(255, 255, 255, 0.25)!important;
}

.cm-dialog-pdf-generation .card-header--title,.cm-dialog-recipients-selection .card-header--title {
	text-align:center;
}

.cm-dialog-pdf-generation .MuiDialog-paper, .cm-dialog-recipients-selection .MuiDialog-paper{
	border-radius: 0.65rem;
}

.cm-dialog-pdf-generation .MuiDialog-paper .card-content{
	padding: 0.25em 0.5em;;
}

body .Menu-CashMonitor.Action  .MuiButton-root.Mui-disabled {
	background-color:transparent!important;
	color:#c7c7c7!important;
}

.cm-dialog-recipients-selection .card-content {
	display:flex;
	flex-direction:column;
	padding: 0.25em 0.5em;
}

.cm-dialog-recipients-selection .submit {
	justify-content:center;
	margin: 0.5em 0.75em;
	background: rgba(122, 123, 151, 0.15);
    /*color: #7a7b97;*/
}

.cm-dialog-recipients-selection .recipients-list {
	display:flex;
	flex-direction:column;
}

.cm-dialog-recipients-selection .recipients-list .field {
	display:flex;
	flex-direction:column;
	padding: 0.25em 0.5em;
	margin: 0.25em 0.5em;
}

.cm-dialog-recipients-selection .recipients-list .field .toggable{
	text-transform:none;
	text-align:left;
	justify-content:flex-start;
	padding: 0.5em 0.75em;
	margin: 0.25em 0.5em;
}

.cm-dialog-recipients-selection .recipients-list .field .toggable.selected{
	background-color: rgba(255, 255, 255, 0.25)!important;
}

.cm-dialog-recipients-selection .recipients-list .label, .cm-dialog-recipients-selection .other-recipients .label {
	display:flex;
}

.cm-dialog-recipients-selection .other-recipients {
	display:flex;
	padding-top: 1.25em;
	padding-right: 1.25em;
    min-width: 280px;
	flex-direction:column;
	justify-content:center;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 500px) { 
	.cm-search-menu {
		
	}
}
