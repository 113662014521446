.device-technical-data-root{
	display:flex;
	flex-grow:1;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	height: 100%;
}

.device-technical-data-root .container {
	display:flex;
	min-height:2.5em;
	width:100%;
	flex-direction:column;
}

.device-technical-data-root .container .module-name {
	display:flex;
	flex-direction:row;
	padding : 0.5em 1em;
	flex-grow:1;
	justify-content:center;
	background-color:rgba( 0 , 0 , 0 , 0.15);
}

.Alternative .device-technical-data-root .card-box.container .module-name  {
    background-color: rgba( 255 , 255 , 255 , 0.15);
}

.device-technical-data-root .container .module-name .title{
	display:flex;
	flex-direction:row;
	flex-grow:1;
	padding-left:120px;
	justify-content:center;
	align-items:center;
}

.device-technical-data-root .container .module-name .mode{
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-self:end;
}

.device-technical-data-root .container .module-name .mode .MuiButton-root{
	background-color:rgba( 0 , 0 , 0 , 0.15);
}

.device-technical-data-root .container .module-name .mode:hover .MuiButton-root{
	background-color:rgba( 0 , 0 , 0 , 0.5);
}

.device-technical-data-root .container .module-details {
	display:flex;
	flex-direction:column;
	flex-grow:1;
	padding: 0.5em 1em;
	justify-content:center;
}

.device-technical-data-root .container .module-details .thermometers {
	display:flex;
	flex-direction:row;
	flex-grow:1;
	padding: 0.5em 1em;
	justify-content:center;
}

.device-technical-data-root .container .module-details .last-check {
	display:flex;
	justify-content:center;
}

.device-technical-data-root .container .module-details .modules-overview {
	display:flex;
	justify-content:center;
	flex-direction:column;
	flex-grow:1;
}

.device-technical-data-root .container .module-details .modules-overview .system{
	/*display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:column;*/
	
	margin-bottom:2em;
}

.device-technical-data-root .container .module-details .modules-overview .system .line{
	/*display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:row;*/
	padding: 1em 1.5em;
}

.device-technical-data-root .container .module-details .modules-overview .system .line .voltage {
	/*display:flex;
	justify-content:center;
	align-items:center;*/
	font-size:1.5em;
	/*margin-right:2.5em;*/
}

.device-technical-data-root .container .module-details .modules-overview .system .line .cycle {
	/*display:flex;
	justify-content:center;
	align-items:center;*/
	font-size:1.5em;
}

.device-technical-data-root .container .module-details .modules-overview .system .line .temperature {
	/*margin-left:2.5em;*/
}

.device-technical-data-root .container .module-details .modules-overview .module-type,
.device-technical-data-root .container .module-details .modules-overview .sensor-counter,
.device-technical-data-root .container .module-details .modules-overview .lower-sensor,
.device-technical-data-root .container .module-details .modules-overview .cycles,
.device-technical-data-root .container .module-details .detail-sensor-line .sensorId,
.device-technical-data-root .container .module-details .detail-sensor-line .sensorName {
	display:flex;
	justify-content:center;
	align-items:center;
}

.device-technical-data-root .container .module-details .detail-sensor-line.selected,
.device-technical-data-root .container .module-details .detail-recycler-line.selected  {
	background-color: rgba( 0 , 0 , 0 , 0.15 );
}

.Alternative .device-technical-data-root .container .module-details .detail-sensor-line.selected,
.Alternative .device-technical-data-root .container .module-details .detail-recycler-line.selected  {
	background-color: rgba( 255 , 255 , 255 , 0.15 );
}

.device-technical-data-root .container .module-details .modules-overview .recyclers{
	display:flex;
	justify-content:center;
	flex-direction:row;
}

.device-technical-data-root .container .module-details .modules-overview .cashbox{
	display:flex;
	justify-content:center;
	flex-direction:row;
}

.device-technical-data-root .container .module-details .modules-overview .loader{
	display:flex;
	justify-content:center;
	flex-direction:row;
}

.device-technical-data-root .container .module-details .detail-recycler-line {
	padding-top:1em;
}

.device-technical-data-root .container .module-details .detail-recycler-line .recycler-value {
	font-size:1.5em;
	font-weight:bold;
}

.device-technical-data-root .container .module-details .detail-recycler-line .qty-dispensed {
	font-size:1.25em;
	font-weight:bold;
}

.device-technical-data-root .container .module-details .loader {
	min-height: 6em;
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
}

.device-technical-data-root .container .module-details .no-data-placeholder.shown {
	display:flex;
	flex-grow:1;
	position:relative;
	top:0;
	align-items: center;
    justify-content: center;
}

.device-technical-data-root .container .module-details .data-block {
	border-radius:0.25em;
	border: solid 1px rgba( 0 , 0 , 0 , 0.15 );
	margin: 1em 0em;
	padding: 0.5em 0em;
}

.Alternative .device-technical-data-root .container .module-details .data-block {
	border-color: rgba( 255 , 255 , 255 , 0.15 );
}

.device-technical-data-root .container .module-details .data-block .type-icon{
	display:flex;
	align-items:center;
	justify-content:center;
}

.device-technical-data-root .container .module-details .data-block .simple-table-label {
	justify-content: flex-start;
    display: flex;
}

.device-technical-data-root .container .module-details .data-block .simple-table-value {
	justify-content: flex-end;
    display: flex;
	padding-right:1.5em;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper {
	
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper {
	justify-content: space-evenly;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.hopper,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.hopper {
	max-width: 140px;
	min-width: 140px;
	justify-content: center;
	align-items: center;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.hopperValue,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.value {
	max-width: 105px;
	min-width: 105px;
	justify-content: center;
	align-items: center;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.accepted,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.accepted {
	max-width: 140px;
	min-width: 140px;
	align-items: center;
	justify-content: flex-start;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.sorted,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.sorted {
	max-width: 140px;
	min-width: 140px;
	align-items: center;
	justify-content: flex-start;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.dispensed,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.dispensed {
	max-width: 140px;
	min-width: 140px;
	align-items: center;
	justify-content: flex-start;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.overcurrent,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .cell.timeout,
.device-technical-data-root .container .module-details .modules-overview .table-hopper .header.errorCoins {
	max-width: 130px;
	min-width: 130px;
	align-items: center;
	justify-content: flex-start;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .footer {
	
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .footer .cell {
	text-align:right;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .footer .cell.Total {
	justify-content: center;
	align-items: center;
}

.device-technical-data-root .container .module-details .modules-overview .table-hopper .footer .cell.amount {
	align-items: center;
	justify-content: flex-end;
}

.not-visible.mode {
	opacity:0;
}
