.Settings-root {
	
}

.Settings-root .content {
	padding:0.25em;
	justify-content:center;
	display:flex;
	flex-direction:column;
}

.Settings-root .content .container {
	border:solid 1px rgba( 0 , 0 , 0 , 0.15 );
	border-radius:1em;
	padding:0.25em;
	margin-top:0.75em;
}

.Alternative .Settings-root .content .container {
	border-color:rgba( 255 , 255 , 255 , 0.15 );
}

.Settings-root .content .container .title {
	position:absolute;
	left:2em;
	margin-top:-1em;
	padding:0 0.5em;
	background-color:#FFFFFF;
	text-decoration:underline;
	font-size:1.10em;
}

.Alternative .Settings-root .content .container .title {
	background-color:#424242;
}

.Settings-root .scheduler {
	display:flex;
	flex-grow:1;
	justify-content:center;
	flex-direction:column;
	padding:1em;
}

.Settings-root .scheduler .No-Data {
	font-size:1.25em;
}

.Settings-root .scheduler .task-list .task-line {
	display:flex;
	flex-direction:row;
	justify-content:center;
	flex-grow:1;
}

.Settings-root .scheduler .task-list .task-line .time {
	height:48px;
	line-height:48px;
	padding: 12px;
}

.Settings-root .scheduler .task-list .task-line .time svg{
	top: -6px;
    position: relative;
}

.Settings-root .scheduler .task-list .task-line .time .time-value{
	height:30px;
	line-height:30px;
	vertical-align:middle;
	display:inline-block;
    position: relative;
	font-size:1.25em;
	font-weight:bold;
	padding: 0 0.5em;
	top:-10px;
}

.Settings-root .scheduler .task-list .task-line .report-counter {
	padding: 12px;
}

.Settings-root .scheduler .task-list .task-line .recipients-counter {
	padding: 12px;
}

.Settings-root .scheduler .task-list .task-line .selected-node {
	flex-grow:1;
	justify-content:center;
	line-height:48px;
	vertical-align:middle;
	text-align:center;
	display:flex;
	flex-direction:row;
	justify-content: flex-start;
    padding-left: 0.75em;
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Icon {
	margin-right:0.5em;
	margin-top:6px;
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Label {
	
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Label .Node-Name {
	font-size:1.15em;
	font-weight:bold;
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Label .Node-Extra {
	font-size:0.95em;
	opacity:0.8;
}

.Settings-root .scheduler .task-list .task-line .delete .MuiButtonBase-root {
	width:48px;
	height:48px;
}

.Settings-root .scheduler .Task-Icon {
	display:flex;
	flex-grow:0;
	justify-content:center;
	align-self:center;
	flex-direction:row;
	min-width:6vh;
	min-height:6vh;
	max-width:6vh;
	max-height:6vh;
	margin-top:1em;
}

.cm-dialog-confirm.MuiDialog-paper.MuiPaper-root {
	background-color:white;
}

.dark.cm-dialog-confirm.MuiDialog-paper.MuiPaper-root {
	background-color:#424242;
}

.dark .text-black-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.dark .MuiButtonBase-root.btn-neutral-secondary {
	background: transparent;
}

.dark .MuiButtonBase-root.btn-neutral-secondary:hover{
	color:black !important;
}

.dark.cm-dialog-confirm .text-danger {
	color: rgba(255, 255, 255, 0.75) !important;
}

.cm-dialog-confirm .text-first {
    color: #3f51b5 !important;
}

.cm-dialog-confirm .MuiButton-root.btn-first {
	background: #3f51b5 !important;
	box-shadow: 0 0.25rem 0.55rem rgba(25, 32, 71, 0.35);
}

.cm-dialog.card-box.config .MuiPaper-root{
	min-width:1em;
	min-height:1em;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root{
    justify-content: center;
    align-items: center;
    display: flex;
	flex-direction:column;
	min-height:20em;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .No-Data{
	/*margin: 1.5em 0;*/
	font-size:1.05em;
	flex-grow:1;
	display:flex;
	max-width:100%;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list {
	flex-grow:1;
	align-items:center;
	display:flex;
	flex-direction: column;
	/*min-height:10em;*/
	min-width:100%;
	/*max-width:10em;*/
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list {
	min-width:100%;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list .task-line {
	display:flex;
	flex-grow:1;
	flex-direction:row;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list .task-line .details {
	flex-grow:1;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list .task-line .recipients-counter {
	padding: 11px;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list .task-line .delete {
	align-self:end;
	display:flex;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root .config-editor-task-list .task-list .task-line .delete .MuiButtonBase-root {
	width:48px;
	height:48px;
}

.cm-dialog.card-box.config .period-line {
	margin-bottom:1em;
}

.MuiMenuItem-root {
	
}

.dark .MuiMenuItem-root {
	color:#FFFFFF;
}

.task-editor , .task-editor .select-task-properties, .select-task-properties, .cm-dialog .search {
	min-width:100%!important;
	margin:0.5em;
	max-width:10em;
}

.task-editor {
	margin-left: -0.5em;
}

.cm-dialog .task-list .task-line .details .task-type {
	font-size:1.15em;
}

.cm-dialog .task-list .task-line .details .task-filter {
	opacity:0.75;
	font-size:0.85em;
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Name {
	text-align:left;
	padding-left:2px;
}

.Settings-root .scheduler .task-list .task-line .selected-node .Node-Location {
	line-height:12px;
	margin-top:-10px;
}

.cm-dialog .label-location , .Node-Location {
	display:flex;
	word-break: break-all;
	justify-content:flex-start;
	width:100%;
	font-size:0.75em;
	opacity:0.75;
	flex-direction:row;
	flex-flow: wrap;
}

.cm-dialog .label-location .path-element , .Node-Location .path-element{
	padding: 0 0.25em;
}

.cm-dialog .label-location p {
	display:block;
	width:100%;
	margin-bottom: 0;
}

.cm-dialog .label-location p.overflow-text-visible {
	white-space: break-spaces;
}

.cm-dialog.card-box.config .MuiPaper-root .MuiDialogContent-root {
	overflow-x: hidden;
}

.disclaimer {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	font-size: 0.75em;
	opacity: 0.65;
	font-style: italic;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}

@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 450px) {
	.cm-dialog .Period-chooser svg {
		display:block;
	}
	
	.cm-dialog .period-line.start {
		/*min-width:21em!important;*/
	}
	
	.cm-dialog .period-line.start .MuiTextField-root {
		width:100%;
	}
	
	.Settings-root .scheduler .task-list .task-line .selected-node .Node-Location {
		margin-top:0px;
	}
	
	.Settings-root .scheduler {
		padding: 12px 0;
	}
	
	.Settings-root .scheduler .task-list .task-line .time {
		padding: 12px 0;
		width:25px;
	}
	
	.Settings-root .scheduler .task-list .task-line .time .time-value {
		font-size:0.95em;
		top:-38px;
		left: -10px;
	}
	
	.Settings-root .scheduler .task-list .task-line .report-counter {
		margin-right:0.5em;
	}
	
	.Settings-root .scheduler .task-list .task-line .selected-node .Node-Label .Node-Name {
		font-size:1em;
		font-weight:bold;
		line-height:1rem;
		padding-top:12px;
	}
}
