.sensor-state-indicator-root {
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.CircularProgressbar.circular-progress-xs {
	width:40px;
	min-width: 40px!important;
}