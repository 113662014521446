.fullscreen {
	width:100%;
}

.MuiTabs-root.hidden {
	display: none;
}

.Alternative .tab-panel-reminder.active .card-box.reminder.operation-reminder .type-reminder {
	background-color: #2a2e33;
	transition: background-color 0.3s ease;
}

.tab-panel-reminder.active .card-box.reminder.operation-reminder .type-reminder {
	background-color: #2a2e33;
	color: white;
	transition: background-color 0.3s ease;
}

.MuiTab-root.Mui-selected {
	/*background-color:red;
	border-radius: 0.29rem !important;*/
}

body .Root.Dashboard.Alternative .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
    background: #717171!important;
    box-shadow: 0 16px 26px -10px rgba(255, 255, 255, 0.16), 0 4px 25px 0 rgba(0, 0, 0, 0.42), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}

body .Root.Dashboard .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
    background: #717171!important;
    box-shadow: 0 16px 26px -10px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

body .Root.Dashboard.Alternative .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover {
    color: #FFFFFF!important;
	opacity:1;
}

body .Root.Dashboard .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover {
    color: #717171!important;
	opacity:1;
}

body .Root.Dashboard .MuiTabs-root.nav-tabs-primary .admin-button.Mui-selected.MuiTab-root:hover {
    color: #FFFFFF!important;
	opacity:1;
}

.tab-panel-content {
	padding-top:1em;
}

.hidden {
	display:none!important;
}

#exportable-reminder .MuiGrid-item {
	padding: 4px;
}

.card-box.reminder.operation-reminder {
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding : 0 0;
}

.card-box.reminder.operation-reminder .type-reminder {
	align-self: start;
	text-align:center;
	flex-grow:1;
	padding : 0.5em 1em;
	min-width:100%;
	width:100%;
	max-width:100%;
	font-size:0.9em;
}

.card-box.reminder.operation-reminder .total-reminder-content {
	display:flex;
	flex-direction:row;
	padding : 0.5em 1em;
	flex-grow:1;
	justify-content:center;
}

.card-box.reminder.operation-reminder .type-reminder {
	background-color:rgba( 0 , 0 , 0 , 0.15);
}

.Alternative .card-box.reminder.operation-reminder .type-reminder {
	background-color:rgba( 255 , 255 , 255 , 0.15);
}

.MuiTableCell-root.MuiTableCell-head.header.error, .MuiTableCell-root.MuiTableCell-head.header.manual,
.cell.error, .cell.manual{
	min-width:6vh;
	width:6vh;
	max-width:6vh;
}

.MuiTableCell-root.MuiTableCell-head.header.date {
	text-align: right!important;
	/*padding-left: 4em;*/
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .type-label {
	text-align: left;
	padding-left: 5em;
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .opt-data-label {
	text-align: left;
	padding-left: 8em;
}

.cm-dialog-error .MuiPaper-rounded {
	border-radius: 0.75em;
}

.error-line-indicator {
	cursor:pointer;
}

.operation-margin {
	margin-top:0.05em !important;
}

.operation-padding {
	padding-left: 0.5em!important;
}

.operation-padding2 {
	padding-left: 1em!important;
}

.MuiTypography-root:not(.MuiDateTimePickerToolbar-separator):not(.MuiTimePickerToolbar-separator) {
	width: 100%;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}
