.Modal-Refresh-overlay {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	background-color: rgba(0,0,0,0.75);
	z-index:100000;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.Modal-Refresh-overlay .MuiPaper-root.MuiCard-root.card-box{
	flex-grow:0!important;
	min-width:10vw;
	max-width: fit-content;
	align-self: center;
}

.Modal-Refresh-overlay .MuiPaper-root.MuiCard-root.card-box .card-footer .MuiButtonBase-root.MuiButton-root {
	min-width:100%;
}