.MuiAppBar-root {
    color:white !important;
    position: fixed !important;
    /*z-index:15000 !important;*/
}
.toolbar-title {
    flex-grow: 1;
}
.menu-button-hidden {
    display: none!important;
}
.toolbar-mui {
    padding-right: 24px;
}
