.Identity {
	display:block;
	width:100%;
	background-color:var(--cashmonitor-identity-bg-color);
	text-align:left;
	transition: all var( --cashmonitor-transition-delay);
	overflow:hidden;
	margin-bottom:0.5em;
}

.Root.Dashboard.Alternative .Identity, .Root.Dashboard.Alternative  .IdCard {
	background-color:transparent!important;
	color:#FFFFFF!important;
}

.Identity:hover , .Root.Dashboard.Alternative .Identity:hover {
	background-color:var(--cashmonitor-identity-hover-bg-color)!important;
	color:var(--amplify-white);
}

.Identity i, .Identity svg{
    margin-left: 0.25em;
    margin-top: 0.1em;
	margin-right: 0.25em;
}

.Identity .Name {
	width: calc(100% - 40px);
    float:right;
    font-size: 0.75em;
    line-height: 1.2;
    vertical-align: middle;
    margin-top: -2em;
	overflow:hidden;
	cursor:pointer;
}

.Identity.opened .Name {
    margin-top: 1em;
}

.Identity .Name .opening-indicator {
	float:right;
	margin-right:1em;
	min-width:12px;
	min-height:12px;
}

.Identity.opened {
	height:120px;
}

.Identity.closed {
	height:32px;
}

.IdCard {
	transition: all var( --cashmonitor-transition-delay);
	background-color:white;
	font-size:0.8em;
	padding:0.5em;
	color:black;
	width:100%;
	height:100%;
}



.closed .IdCard {
	opacity:0;
}

.opened .IdCard {
	opacity:1;
}

.IdCard .InfoLabel {
	font-weight:bold;
	display:inline-block;
	width:15%;
}

.IdCard .InfoValue {
	font-style:italic;
	display:inline-block;
	width:80%;
	line-break: anywhere;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.Identity .Name {
		font-size: 0.65em;
		margin-top: -2.75em;
	}
	
	.Identity.opened .Name {
		margin-top: 0.75em;
	}
	
	.Identity .Name .opening-indicator {
		margin-top: -0.6em;
	}

	.IdCard .InfoLabel {
		width:15%;
		clear:left;
		font-size: 0.70em;
	}

	.IdCard .InfoValue {
		width:84%;
		clear:right;
		font-size: 0.75em;
	}
}
