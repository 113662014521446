.flip-card-cm {
	height:5.5em;
	line-height:5.5em;
	vertical-align:middle;
	width:1.5em;
	text-align:right;
	margin-right:0.25em;
}

.cm-card-data {
	width:calc(100% - 6em);
	text-align: left;
}

.cm-card-data.extended {
	width:calc(40% - 6em);
	text-align: left;
	min-width: 7.5em;
}

.circular-progress-cm {
	min-width:4.5em;
	width:4.5em;
}

.cm-level-adjustement-block {
	text-align:left;
	width: 4em;
}

.cm-level-adjustement-block .side-indicator {
	float:left;
	/*max-height:1em;*/
	/*height:1em;*/
	/*overflow:hidden;*/
	/*font-size:1.5em;*/
	/*vertical-align: middle;*/
    /*line-height: 1em;*/
	/*margin-top:-.05em;*/
	margin-right:0.5em;
	clear:left;
}

.cm-level-adjustement-block .side-indicator.down {
	color:#11c5db !important;
}

.cm-level-adjustement-block .side-indicator.up {
	color:#f83245 !important;
}

.cm-level-adjustement-block .side-indicator.regulate {
	color:#1bc943 !important;
}

.cm-extended-details {
	width:60%;
	padding:0.25em;
}

.cm-extended-details-sm {
	display:none!important;
}

.cm-detail-content {
	clear:both;
	width:100%;
}

.forced-line {
	clear:both!important;
	display:block!important;
	width:100%!important;
}

.cm-line-separator{
	display:block;
	width:80%;
	height:0.25em;
	margin: auto;
	margin-top:0.5em;
	background-color:rgba( 255 , 255 , 255 , 1 );
}

.currency-block-list {
	display:block!important;
}

.currency-block-list .currency-header {
	width:100%;
	font-weight:bold;
}

.currency-block-list .currency-content {
	width:100%;
}

.same-currency-list, .line-cm , .currency-block-list{
	width:100%;
}

.line-cm .left, .line-cm .right {
	float:left;
}

.line-cm .left {
	width:45%;
}

.front .line-cm .left {
	width:100%;
}

.front .line-cm .right {
	display:none!important;
}

.line-cm .right {
	width:55%;
	text-align:right!important;
}

.line-cm .qty {
	display:inline-flex;
}

.line-cm .multiplicator {
	margin-left:0.25em;
	margin-right:0.25em;
	display:inline-flex;
}

.line-cm .amount {
	display:inline-flex;
}

.line-cm .total {
	
}


/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.cm-extended-details {
		display:none!important;
	}
	
	.cm-extended-details-sm {
		display:flex!important;
		width:100%;
		margin:0 auto 0.25em auto;
		padding:0.25em;
	}
	
	.cm-extended-details-sm .graph-cm.large {
		margin-left:-38px;
	}
	
	.cm-extended-details-sm .graph-cm {
		margin-left:-15px;
	}
}


@media (max-width: 400px) { 
	.line-cm .left {
		width:50%;
	}

	.line-cm .right {
		width:50%;
	}
}