.DeviceSchema-root {
	/*margin:auto;*/
	/*margin-top: 5em;*/
}

.Schema-container {
	/*display:flex;
	align-self:center;
	justify-self:center;*/
	max-width: 500px;
	max-height:700px;
}

.DeviceSchema-root-small {
	position:absolute;
	bottom:0;
	margin-bottom: 2.5em;
}

.DeviceSchema-root.back {
	/*margin-left: 6.5em;*/
}

.DeviceSchema-root-small.front {
	margin-left:-1em;
}

.DeviceSchema-root .component, 
.DeviceSchema-root-small .component {
	position:absolute;
}

.swap-icon {
	cursor:pointer;
	z-index:1000;
}

.grid-root {
	
}

.grid-root .grid-item {
	
}
