.snackbar {
  visibility: hidden;
  min-width: 15em;
  margin-left: -7.5em;
  background-color: rgba( 0 , 0 , 0 , 0.75 );
  color: white;
  text-align: center;
  border-radius: 0.25em;
  padding: 1em;
  position: fixed;
  z-index: 1;
  right: 2.5em;
  top: 5em;
  font-size: 1rem;
  display:flex;
}

.snackbar.show {
  visibility: visible; 
  z-index: 10000;

}

.shortDuration {
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.75s;
	animation: fadein 0.5s, fadeout 0.5s 2.75s;
}

.longDuration {
	-webkit-animation: fadein 0.5s, fadeout 0.5s 4.75s;
	animation: fadein 0.5s, fadeout 0.5s 4.75s;
}

.message{
	margin-right:1em;
	margin-left:1em;
	display:flex;
	flex-grow:1;
	justify-content:center;
	align-items:center;
}

.success{
	color:#1bc943;
}

.warning{
	color:rgb(244, 119, 46);
}

.info{
	color:#11c5db;
}

.error{
	color:rgb(248, 50, 69);
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {top: 0; opacity: 0;} 
  to {top: 5em; opacity: 1;}
}

@keyframes fadein {
  from {top: 0; opacity: 0;}
  to {top: 5em; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 5em; opacity: 1;} 
  to {top: 0; opacity: 0;}
}

@keyframes fadeout {
  from {top: 5em; opacity: 1;}
  to {top: 0; opacity: 0;}
}