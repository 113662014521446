.GroupedChart-root {
	display:flex;
	flex-direction:column;
	align-items:center;
	/*justify-content:center;*/
	padding:0.5em 0em;
	max-height:15em;
	overflow-y:auto;
}

.GroupedChart-root .item-graph{
	display:flex;
	flex-grow:1;
	width:100%;
	flex-direction:row;
	height: 1.5em;
	align-items:center;
	justify-content:flex-start;
}

.GroupedChart-root .item-graph .label{ 
	display:flex;
	padding-left:2.5%;
	padding-right:2.5%;
	font-size:0.85em;
	min-width:100px;
}

.GroupedChart-root .item-graph.XL .label{ 
	min-width:335px;
}

.GroupedChart-root .item-graph.XL .progress{
	max-width:30%;
}

.GroupedChart-root .item-graph .progress{
	display:flex;
	max-width:60%;
	flex-grow:1;
	height:100%;
	justify-content: center;
    align-items: center;
	border-radius:0.25em;
	overflow:hidden;
}

.GroupedChart-root .item-graph .progress .container{
	position:absolute;
	width:52.5%;
	border-radius:0.25em;
	overflow:hidden;
	margin-top:0.5em;
	height:1.5em;
	margin-left:2.5%;
	background-color: rgba( 190 , 190 , 190 , 1 );
}

.GroupedChart-root .item-graph .progress .progress-bar-rounded {
	width: 100%;
}

.GroupedChart-root .item-graph .quatity{
	display:flex;
	max-width:10%;
	padding-left:2.5%;
	padding-right:2.5%;
	font-size:1.25em;
	align-items:center;
	justify-content:center;
}

.GroupedChart-root .item-graph .progress .progress-bar-gradient {
	background: rgba( 190 , 190 , 190 , 0.1 );
}

.GroupedChart-root .item-graph .progress .MuiLinearProgress-barColorPrimary {
	background: linear-gradient(0.25turn, rgb(0, 227, 150) , rgb(0, 227, 150) , rgb(254, 176, 25), rgb(255, 69, 96));
}

.GroupedChart-root .item-graph.Alt-1 .progress .MuiLinearProgress-barColorPrimary {
	background: linear-gradient(0.25turn, rgb(0, 227, 150), rgb(0, 227, 150) );
}

/*.GroupedChart-root .item-graph.Alt-3 .progress .MuiLinearProgress-barColorPrimary {
	background: linear-gradient(0.25turn, rgb(0, 227, 150) , rgb(0, 227, 150) , rgb(0, 227, 150)  );
}

.GroupedChart-root .item-graph.Alt-4 .progress .MuiLinearProgress-barColorPrimary {
	background: linear-gradient(0.25turn, rgb(0, 227, 150), rgb(0, 227, 150) , rgb(0, 227, 150) , rgb(0, 227, 150) , rgba(255,255,102) );
}*/

.GroupedChart-root .item-graph.Alt-2 .progress .MuiLinearProgress-barColorPrimary {
	background: linear-gradient(0.25turn, rgb(0, 227, 150) , rgb(0, 227, 150) , rgb(0, 227, 150) , rgb(254, 176, 25));
}