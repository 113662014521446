.InventoryAlertConfig-root {
	display:flex;
	flex-grow:1;
	min-height:2.5em;
	padding: 0.5em 0.5em;
	flex-direction: column;
}

.InventoryAlertConfig-root .row {
	display:flex;
	flex-grow:1;
	min-height:2.5em;
	margin-bottom: 0.75em;
}

.InventoryAlertConfig-root div{
	display:flex;
	justify-content:center;
	align-items:center;
}

.InventoryAlertConfig-root div.expandable{
	flex-grow:1;
}

.InventoryAlertConfig-root div.label{
	padding: 0em 0.5em;
}

.InventoryAlertConfig-root div .toggle-btn-inventory-alert-sending {
	opacity:0.5;
	margin-right:0.5em;
}

.InventoryAlertConfig-root div .toggle-btn-inventory-alert-sending.active {
	opacity:1;
}

.InventoryAlertConfig-root .row .spacer {
	width: 10px;
}


.InventoryAlertConfig-root .row .combo-temporal-unit {
	margin-left: 1em!important;
}

.Alternative .combo-temporal-unit {
	color:white !important;
}

.left-align {
	align-items:start !important;
	justify-content: start !important;
	
}
