.material-container.technical-alert {
    margin-top: 1.5em;
    min-height: 20em;
}

.material-container.technical-alert.no-data {
    min-height: 5em;
}

.cleanup-alert-container ,
.cleanup-alert-container .indicator {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 0 0.5em;
    margin: 0 0.5em;
    align-items: center;
    justify-content: center;
}

.cleanup-alert-container .indicator .icon {
    margin-right: 0.5em;
    font-size: 1.65em;
    cursor: pointer;
}

.cleanup-alert-container .indicator .label {
    font-size: 1.25em;
    font-weight: bold;
}


.indicator.success .icon svg,
.indicator.success .label {
    color: #1bc943;
}

.indicator.warning .icon svg,
.indicator.warning .label {
    color: rgba(244, 119, 46, 1)
}

.indicator.danger .icon svg,
.indicator.danger .label {
    color: #f83245;
}

.payStation-card .general-block .box-name.large {
    max-width: 100%;
}

.payStation-card.bordered {
    border: 1px solid rgba( 255 , 255 , 255 , 0.6 );
    border-radius: 0.5em;
}
