.spining {
	animation-name: spin;
	animation-duration: 2500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.spaced {
    margin-top: 2.5em;
    margin-bottom: 1.5em;
}

.big {
    width: 5em;
    font-size: 5em;
}

.display-4.space-top {
    top: 0.2em;
    position: relative;
}