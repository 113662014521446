.material-container.long {
    margin-top: 1.5em;
    min-height: 21.75em;
}

.material-container.long.no-data {
    min-height: 36.75em;
}

.material-container.long .container-content {
    max-height: 35em;
}

.modules-states {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.modules-states .label {
    display: flex;
}

.device-state {
    display: flex;
    width: 100%;
    margin-top: 0.5em;
    align-items: center;
}

.modules-states .device-state .identity {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.5em;
}

.modules-states .device-state .model {
    font-size: 0.8em;
    opacity: 0.85;
    align-items: center;
    justify-content: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.modules-states .device-state .serial {
    font-size: 0.8em;
    opacity: 0.75;
    align-items: center;
    justify-content: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.modules-states .device-state .icons {
    display: flex;
    justify-content: space-evenly;
    font-size: 1.5em;
    flex-direction: column;
    width: 4em;
}

.state-text {
    font-size: 0.5em;
    font-style: italic;
}

.last-reception {
    display: flex;
    flex-grow: 1;
    font-size: 0.75em;
    opacity: 0.75;
}

.navigation-diagnostic {
    display: flex;
    font-size: 1.25em;
    width: 3em;
}

.last-error {
    width: 4em;
    /*font-size: 1.5em;*/
}

.last-error svg{
    cursor: pointer;
}

.messages-line {
    display: flex;
    flex-direction: row;
}

.messages-icon {
    /*font-size: 1.25em;*/
    margin-right: 0.5em;
    width: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.account-details {
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1px solid rgba( 255 , 255 , 255 , 0.75 );
    width: 90%;
    font-size: 0.9em;
}

.latest-operation-available {
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1px solid rgba( 255 , 255 , 255 , 0.75 );
    width: 90%;
    font-size: 0.9em;
}

.account-details .account-line ,
.latest-error-available  .error-line,
.technical-advice  .tip-line {
    display: flex;
    flex-direction: row;
}

.account-details .account-line .account-label {
    display: flex;
    align-items: flex-start;
    width: 7em;
}

.account-details .account-line .account-value {
    display: flex;
    flex-grow: 1;
}

.account-details .account-line .account-value span {
    margin-left: 0.5em;
    color: rgba( 255 , 255 , 255 , 0.75 ) !important;
}

.account-details .account-line .account-info {
    display: flex;
    align-items: flex-end;
}

.account-details .account-line .account-info svg,
.latest-error-available .error-line .error-navigation svg {
    /*font-size: 1.5em;*/
}

.latest-error-available .error-line .error-label {
    display: flex;
    align-items: flex-start;
    width: 9em;
    min-width: 9em;
}

.latest-error-available .error-line .error-value {
    display: flex;
    flex-grow: 1;
    text-align: left;
}

.latest-error-available .error-line .error-navigation {
    display: flex;
    align-items: flex-end;
    /*margin-top: 1em;*/
}

.latest-error-available {
    margin-bottom: 0.75em;
    padding-bottom: 0.75em;
    border-bottom: 1px solid rgba( 255 , 255 , 255 , 0.75 );
    margin-top: 0.75em;
    padding-top: 0.75em;
    border-top: 1px solid rgba( 255 , 255 , 255 , 0.75 );
    width: 90%;
    font-size: 0.9em;
}

.latest-operation-available {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.operation-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
}

.operation-type .operation-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-left: 0.5em;
}

.operation-type .operation-navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.technical-advice {
    font-size: 0.9em;
    width: 90%;
}

.technical-advice .tip-line .tip-label {
    display: flex;
    align-items: flex-start;
    width: 10em;
}

.technical-advice .tip-line .tip-value {
    display: flex;
    flex-grow: 1;
}

.not-installed{
    color:#424242;
}

svg.online , .last-error svg.level0 {
    color:#1bc943;
}

svg.offline , .last-error svg.level2 {
    color:#f83245;
}

svg.partially , .last-error svg.level1 {
    color:rgba(244, 119, 46, 1);
}

svg.stopped {
    color:#11c5db;
}

svg.maintenance-required {
    color:rgb(17, 197, 219);
}

svg.under-maintenance {
    color:purple;
}

.monitoring .MuiIconButton-root , .monitoring .MuiButtonBase-root {
    background-color: rgba( 255 , 255 , 255 , 0.1 ) !important;
}
