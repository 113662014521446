.comparison-table .MuiTableCell-root{
	text-align:right!important;
}

.comparison-table .icon-header {
	margin-top:-4px;
}

.comparison-table .header {
	
}

/*.comparison-table .header.clock , .comparison-table .cell.hour {
	max-width:100px;
	width:80px;
}*/

#exportable-table {
	margin-bottom: 1.5em;
}

.comparison-table .header.clock {
	padding: 6px 0 6px 0;
}

.comparison-table .cell.hour {
	padding: 6px 45px 6px 16px;
}

/*.comparison-table .header.period1, .comparison-table .header.period2 {
	max-width:230px;
	width:230px;
}*/

.App .Root.Dashboard .period1 .MuiButtonBase-root, .period1,.period1 .MuiTableSortLabel-active{
	color:#008ffb!important;
}

 
.App .Root.Dashboard .period2 .MuiButtonBase-root, .period2,.period2 .MuiTableSortLabel-active{
	color:#e39600!important;
}

.periods {
	display:flex;
	flex-direction:row;
	justify-content: center;
}

.comparison-table .cell.amount {
	padding: 6px 16px 6px 16px;
}

/*.comparison-table .header.gap {
	max-width:50px;
	width:30px;
}*/

.order-selector {
	display:flex;
	flex-grow:1;
	padding:0.25em;
}

.order-selector .label-icon {
	margin:0.25em 0.5em;
}

.order-selector .label-icon .display-4{
	font-size:1.3rem;
}

.order-selector .label-text {
	flex-grow:1;
	text-align:left;
	line-height:30px;
	vertical-align:middle;
}

.order-icon {
	/*align-self:flex-end;
	margin-right:0.5em;
	overflow:hidden;
	*/
	display:inline-block;
	max-height:30px;
	min-height:30px;
	height:30px;
	overflow:hidden;
	padding-top:0.5em;
	margin-left:0.25em;
}
.order-icon svg.reversed{
	transform:rotate(180deg);
	margin-top:-0.5em;
}

.type-selector{
	margin-top:0.5em;
	text-align:right;
}

.type-selector .Item {
	background-color:rgba( 0 , 0, 0, 0.25 );
	margin: 0 0.25em;
	min-height:28px;
	max-height:28px;
}

.Alternative .type-selector .Item {
	background-color:rgba( 255 , 255, 255, 0.25 );
}

.type-selector .Item.last {
	margin-right: 0.5em;
}

.comparison-placeholder-no-data.shown{
	display:flex;
	min-height:5em;
	max-height:5em;
	height:5em;
	position:relative;
	top:1.8em;
	justify-content:center;
}

.comparison-table .cell.hour, .comparison-table .cell.highlight {
	font-weight:bold;
}

.App .Root.Dashboard .comparison-table .cell.positive {
	color:#1bc943!important;
}

.App .Root.Dashboard .comparison-table .cell.negative {
	color:#f83245!important;
}

.App .Root.Dashboard .comparison-table .cell.equals {
	color:#f4772e!important;
}

.comparison-table .selectable-row {
	cursor:pointer;
}

.comparison-table .selectable-row.selected {
	background-color: rgba( 0 , 0 , 0 , 0.15 );
}

.Alternative .comparison-table .selectable-row.selected {
	background-color: rgba( 255 , 255 , 255 , 0.15 );
}

.cm-graph-xl.comparison {
	display:flex;
	flex-grow:1;
}

.Alternative .cm-graph-xl.comparison {
	color:#FFFFFF!important;
}

.Alternative .cm-graph-xl.comparison .apexcharts-text.apexcharts-xaxis-label {
	color:#FFFFFF!important;
}

.cm-graph-xl.comparison .cm-chart {
	width:100%;
}

.cm-graph-xl.comparison .cm-chart .apexcharts-canvas {
	width:100%!important;
	min-width:100%!important;
	max-width:100%!important;
}

.cm-graph-xl.comparison .apexcharts-inner.apexcharts-graphical {
    transform: translate(1.8rem, 1.4rem);
}

.cm-graph-xl.comparison .apexcharts-inner.apexcharts-graphical .apexcharts-datalabels .apexcharts-data-labels rect {
   /*fill:#1bc943;*/
   fill:#008ffb  ;
}

.cm-graph-xl.comparison .apexcharts-inner.apexcharts-graphical .apexcharts-datalabels:nth-child(2n) .apexcharts-data-labels rect {
   /*fill:#f83245;*/
   fill:#e39600;
}

.cm-graph-xl.comparison .apexcharts-tooltip {
    top: -1em!important;
}

.Alternative .apexcharts-toolbar .apexcharts-menu-icon svg{
	fill:#FFFFFF!important;
}

.apexcharts-toolbar .apexcharts-menu-icon {
	display:none;
}

.Alternative .apexcharts-toolbar .apexcharts-menu {
	background-color:#303030;
}

.Alternative .apexcharts-toolbar .custom-icon.menu-chart {
	color:#FFFFFF;
}

.mode-bar .comparison-table, .mode-table .comparison{
	/*display:none;*/
}

.gap-label {
	display:inline-block;
	line-height:30px;
	height:30px;
	vertical-align:middle;
	margin-top:-10px;
}

.Alternative text.apexcharts-text.apexcharts-xaxis-label, .Alternative text.apexcharts-text.apexcharts-yaxis-label{
	fill:#FFFFFF;
}
/*
.MuiTableRow-hover:nth-of-type(even) {
	background-color: rgba( 0 , 0 , 0 , 0.15 );
}

.Alternative .MuiTableRow-hover:nth-of-type(even) {
	background-color: rgba( 255 , 255 , 255 , 0.15 );
}
*/

.MuiTableRow-hover:nth-of-type(odd) {
	background-color: rgba( 0 , 0 , 0 , 0.02 );
}

.Alternative .MuiTableRow-hover:nth-of-type(odd) {
	background-color: rgba( 255 , 255 , 255 , 0.02 );
}

.card-box.reminder.comparison-reminder {
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding : 0 0;
}

.card-box.reminder.comparison-reminder .type-reminder {
	align-self: start;
	text-align:center;
	flex-grow:1;
	padding : 0.5em 1em;
	min-width:100%;
	width:100%;
	max-width:100%;
	font-size:0.9em;
}

.card-box.reminder.comparison-reminder .total-reminder-content {
	display:flex;
	flex-direction:row;
}

.card-box.reminder.comparison-reminder .total-period-content {
	padding : 0.5em 1em;
	flex-grow:1;
}

.card-box.reminder.comparison-reminder .total-comparator {
	min-width:5em;
	padding : 0.5em 1em;
}

.card-box.reminder.comparison-reminder .type-reminder {
	background-color:rgba( 0 , 0 , 0 , 0.15);
}

.card-box.reminder.comparison-reminder .total-comparator .positive {
	color: #1bc943;
	transform:rotate(45deg);
}

.card-box.reminder.comparison-reminder .total-comparator .negative {
	color: #f83245;
	transform:rotate(-45deg);
}

.card-box.reminder.comparison-reminder .total-comparator .equals {
	color: #f4772e;
}

.Alternative .card-box.reminder.comparison-reminder .type-reminder {
	background-color:rgba( 255 , 255 , 255 , 0.15);
}

.Alternative .tab-panel-reminder.active .card-box.reminder.comparison-reminder .type-reminder {
	background-color: #2a2e33;
	transition: background-color 0.3s ease;
}

.tab-panel-reminder.active .card-box.reminder.comparison-reminder .type-reminder {
	background-color: #2a2e33;
	color: white;
	transition: background-color 0.3s ease;
}

/*.header.period2 , .header.period1 , .cell.operationAmountPeriod2 , .cell.operationAmountPeriod1 {
	max-width: 275px;
}*/

.header.period1 , .cell.operationAmountPeriod1 {
	max-width: 275px;
}

/*.header.period2 , .header.period1 , .cell.operationAmountPeriod2 , .cell.operationAmountPeriod1 {
	max-width: 275px;
}*/

.header.period1 {

}

.header.gap , .cell.gapAmountPeriod {
	max-width: 275px;
}

.comparison-table .cell.hour , .comparison-table .header.clock {
	min-width: 200px;
	max-width: 200px;
}

.comparison-table .header.clock span {
	margin-left: 0.5em;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (min-width: 450px) and (max-width: 960px) { 
	/*.apexcharts-legend.apexcharts-align-center.position-bottom {
		top: 75%;
	}*/
}

@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	.apexcharts-legend.apexcharts-align-center.position-bottom {
		bottom:0%!important;/*opera 7%*/
	}
	
	.Opera .apexcharts-legend.apexcharts-align-center.position-bottom {
		bottom:10%!important;/*opera */
	}
	
	.Root .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.Mui-disabled svg{
		display:none;
	}
	
	.Root .MuiButtonBase-root {
		flex-grow:1;
	}
}

@media (min-width: 800px) and (max-width: 1750px) and (orientation: portrait) { 
	.apexcharts-legend.apexcharts-align-center.position-bottom {
		bottom:0%!important;
	}
	
	.Opera .apexcharts-legend.apexcharts-align-center.position-bottom {
		bottom:9%!important;/*opera */
	}
	
	body .Root .MuiTabs-root .MuiTabs-scroller {
		overflow: hidden !important;
		overflow-x: auto !important;
	}
	
	.Root .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.Mui-disabled svg{
		display:none;
	}
	
	.Root .MuiTabScrollButton-root {
		width:25px;
	}
}

@media (max-width: 450px) { 
	.MuiTabs-root.nav-tabs-primary.operations .MuiTabs-flexContainer .MuiButtonBase-root:nth-child(2) .MuiTab-wrapper {
		line-break:anywhere;
	}
	
	.MuiTabs-root.nav-tabs-primary .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper {
		font-size:0.85em;
	}
	
	.MuiTabs-root.nav-tabs-primary .MuiTabs-flexContainer .MuiButtonBase-root .MuiTab-wrapper svg {
		font-size: 2em;
		display:none;
	}
	
	.MuiTableCell-root.MuiTableCell-body.cell.hour {
		font-size:0.85em;
	}
	
	.MuiTableCell-root.MuiTableCell-body.cell.amount {
		font-size:0.85em;
	}
	
	.header.period1 , .header.period2 {
		font-size:0.85em;
	}
	
	.MuiTableCell-root {
		padding: 16px 4px;
	}
	
	.comparison-table .cell.hour {
		padding: 16px 2px 6px 4px;
	}
	
	.Period-chooser .label-period {
		font-size:0.95em;
	}
	
	.Period-chooser .MuiButtonBase-root.MuiButton-root.MuiButton-text.Icon {
		padding: 6px 0;
	}
	
	.Period-chooser svg {
		display:none;
	}
	
	body .Root .MuiTabs-root .MuiTabs-scroller {
		overflow: hidden !important;
		overflow-x: auto !important;
	}
	
	.Root .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.Mui-disabled svg{
		display:none;
	}
	
	.Root .MuiTabScrollButton-root {
		width:25px;
	}
	
	.comparison-table .header.period1, .comparison-table .header.period2 {
		min-width: 94px;
		padding: 16px 0;
	}
	
	.comparison-table .header.type, .comparison-table .cell.type {
		padding: 16px 4px;
	}
	
	.cm-graph-auto.distribution .apexcharts-tooltip.apexcharts-active {
		left:0!important;
		/*z-index:1400;*/
	}
	
	.cm-graph-auto.distribution .apexcharts-tooltip.apexcharts-active .apexcharts-tooltip-series-group .apexcharts-tooltip-text .apexcharts-tooltip-y-group.font-size-lg {
		font-size: 0.85rem !important;
	}
}

@media (max-width: 450px) {
	.Root .MuiTabScrollButton-root {
		width:25px;
	}

	.Root .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.Mui-disabled svg{
		display:none;
	}
	
	body .Root .MuiTabs-root .MuiTabs-scroller {
		overflow: hidden !important;
		overflow-x: auto !important;
	}

	.comparison-table .cell.hour,
	.comparison-table .header.clock {
		min-width: 50px!important;
		width: 50px!important;
		max-width: 50px!important;
	}
}
