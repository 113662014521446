/*Don't import it beside in UserManager.js*/
.userlist-id, .userlist-account, .userlist-email, .userlist-phone, .userlist-type, .userlist-groups, .userlist-name, .userlist-status,
.userlist-element-id, .userlist-element-name, .userlist-element-account, .userlist-element-email, .userlist-element-phone, .userlist-element-type, .userlist-element-groups, .userlist-element-status {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.userlist-id, .userlist-element-id {
    min-width: 5.75em;
}
.userlist-id {
    margin-left: 0.75em;
}

.userlist-name, .userlist-element-name {
    min-width: 12em;
}

.userlist-account, .userlist-element-account  {
    min-width: 20em;
}

.userlist-email, .userlist-element-email {
    min-width: 17em;
}

.userlist-phone, .userlist-element-phone {
    min-width: 15em;
}

.userlist-type, .userlist-groups, .userlist-element-type, .userlist-element-groups {
    justify-content: center;
    min-width: 7em;
}

.userlist-type, .userlist-element-type {
    padding-right: 1em;
    justify-content: center;
}

.userlist-groups, .userlist-element-groups {
    min-width: 11em;
    justify-content: center;
}
.userlist-status, .userlist-element-status {
    min-width: 4em;
    justify-content: center;
}

.payStation-card .success svg {
    color: rgb(27, 201, 67);
}

.payStation-card .warning svg {
    color: rgb(244, 119, 46);
}
