
.type-selector .left-part {
	float:left;
	padding-left:0.5em;
}

.App .Root.Dashboard .type-selector .left-part .period1 .MuiButton-label{
	color:#008ffb!important;
}

.App .Root.Dashboard .type-selector .left-part .period2 .MuiButton-label{
	color:#e39600!important;
}

.gap.distrib .gap-label {
	margin-top:-3px;
}

.order-icon-box {
	float:right;
	height:100%;
}

.comparison-table .header.type , .comparison-table .cell.type {
	/*max-width:200px;*/
	width:200px;
}

.comparison-table .cell.type .type-label {
	text-align:left;
	width: 400px;
}

.comparison-table .cell.type .opt-data-label {
	font-size:70%;
	text-align:left;
}

.comparison-table .footer .cell.Total {
	text-align:left!important;
}

.order-icon-box button {
	display:block;
	max-height: 15px;
	min-height: 15px;
	height: 15px;
	padding:0px 5px;
}

.order-icon-box button svg.reversed{
	transform:rotate(180deg);
	margin-top:-0.5em;
}

.Root.Dashboard .order-icon-box button svg.selected {
	color:#008ffb!important;
}

.comparison-table .header .header-content{
	line-height:30px;
	height:30px;
	vertical-align:middle;
}

.cm-graph-auto.distribution {
	display:flex;
	justify-content:center;
}

.cm-graph-auto.distribution .apexcharts-legend .apexcharts-legend-series {
	float:left;
	clear:both;
	display:block;
	width:60%;
	margin-left:30%!important;
	text-align:left;
}

.cm-graph-auto.distribution .apexcharts-legend {
	position:relative;
	bottom:0;
}

.cm-graph-xl.distribution .apexcharts-tooltip {
    /*top: 0em!important; */
}

.apexcharts-tooltip-text-z-label.xl {
	min-width:145px;
	width:145px;
	display:inline-block;
}

#chart-distribution {
	min-height:100%!important;
}

#chart-distribution .apexcharts-canvas {
	min-height:100%!important;
}

#chart-distribution .apexcharts-canvas .apexcharts-svg {
	min-height:100%!important;
}

.no-click {
	cursor:default!important;
}

.header.sale-number , .cell.amount.totalCashIn , .header.cash-in , .cell.amount.totalNbCashIn {
	min-width: 350px;
	max-width: 350px;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 450px) { 
	.cm-graph-auto.distribution .apexcharts-legend .apexcharts-legend-series {
		margin-left:0.25em!important;
		width:100%!important;
	}
	
	.apexcharts-legend.position-bottom .apexcharts-legend-series, 
	.apexcharts-legend.position-top .apexcharts-legend-series {
		align-items: flex-start;
	}

	.header.sale-number,
	.cell.amount.totalCashIn,
	.header.cash-in,
	.cell.amount.totalNbCashIn {
		min-width: 90px!important;
	}

	.comparison-table .header.type:not(.clock),
	.comparison-table .cell.type:not(.clock) {
		width: 140px;
		min-width: 0!important;
	}
}
