.HardwareOverview-root {
	display:flex;
	flex-grow:1;
	min-height:2.5em;
	padding: 1.5em 0.5em;
	flex-direction: column;
}

.HardwareOverview-root .container {
	margin-bottom:0.25em;
	padding-bottom:0.25em;
}

.HardwareOverview-root .container.graphCard {
	border: solid 1px rgba( 0 , 0 , 0 , 0.25 );
	display:flex;
	flex-direction:column;
}

.Alternative .HardwareOverview-root .container.graphCard {
	border: solid 1px rgba( 255 , 255 , 255 , 0.25 );
}

.apexcharts-tooltip.apexcharts-active {
	background-color:#303030!important;
}

.apexcharts-tooltip.apexcharts-active .category {
	color:white;
}

.apexcharts-text.apexcharts-yaxis-label  {
	font-size:0.65em;
}

.dark .apexcharts-text.apexcharts-xaxis-title-text {
	color:white;
	fill:white;
}

.title-graph {
	display:flex;
	align-self:center;
	font-weight:bold;
	margin-bottom: 0.25em;
}
