.selector-container {
	display:flex;
	align-items:flex-start;
	margin-top:1.5em;
	/*margin-bottom:1.5em;*/
	margin-left:1.5em;
}

.select-device-selector, .select-device-selector{
	min-width:25em!important;
	margin:0.5em;
	/*max-width:25em!important;*/
}

.dark .text-black-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.dark .MuiMenuItem-root {
	color:#FFFFFF;
}

.combo-device-selector .MuiMenu-list{
	padding:0 !important;
}

@media (max-width: 960px) {
	.select-device-selector {
		max-width: 240px;
		width: 240px;
		min-width: 240px!important;
	}
}
