.spining {
	animation-name: spin;
	animation-duration: 2500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear; 
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.slash {
	position: absolute;
	width:80%;
	height:0.15em;
	background-color:white;
	transform: rotate(45deg);
	box-shadow: 0px 0px 0.25em rgba( 0 , 0 , 0 , 0.5 );
	border-radius:0.15em;
}