.cm-dialog-change-password {
	display:flex;
	min-width:250px;
}

.cm-dialog-change-password.dark svg {
	color: white;
}

.cm-dialog-change-password .title {
	display:flex;
	flex-grow:1;
	align-self: center;
	align-content:center;
	justify-content:center;
	padding: 1em 0em;
	font-size:1.15em;
}

.cm-dialog-change-password .body-form, .cm-dialog-change-password-move .body-form {
	display:flex;
	flex-direction:column;
	flex-grow:1;
	min-height:2em;
	min-width: 255px;
}

.cm-dialog-change-password .body-form .field {
	margin-bottom:1em;
}

.cm-dialog-change-password .body-form .field .icon-btn {
	cursor:pointer;
	width:20px;
}

.cm-dialog-change-password .MuiOutlinedInput-input {
	padding: 0.5em 1em;
	min-width: 300px;
}

.cm-dialog-change-password .MuiInputLabel-outlined {
	transform: translate(0.75em, 0.75em) scale(1);
}

.cm-dialog-change-password-move .body-form .title {
	font-size:1.15em;
	margin:0.75em 0em;
}

.cm-dialog-change-password-move .body-form .search {
	margin-bottom:0.5em;
}

.cm-dialog-change-password .action-bar-dialog {
	display:flex;
	flex-grow:1;
	align-self:end;
	margin-top: 1.5em;
}

.cm-dialog-change-password .action-bar-dialog button{
	width:100%;
}
