
.InputRecipients-root .MuiFormControl-root {
	min-width:100%;
	margin-left:0.5em;
	margin-top:0.25em;
}

.InputRecipients-root.dark .MuiFormControl-root .MuiFormLabel-root {
	color:#FFFFFF!important;
}

.InputRecipients-root.dark {
	/*background-color: #424242!important;*/
}

.email-container {
	display:flex;
	flex-direction:row;
	flex-flow: wrap;
	min-width:100%;
	margin-left:0.5em;
	margin-top:0.25em;
	/*padding:0.25em 0.5em;*/
}

.email-container .recipient-item {
	margin: 0.25em;
	border:solid 1px rgba( 255 , 255 , 255 , 0.5 );
	padding:0.25em 0.5em;
	border-radius:0.5em;
	display:flex;
	flex-direction:row;
}

.email-container .recipient-item .label {
	
}

.email-container .recipient-item .btn-delete {
	align-self:end;
	cursor:pointer;
}

.email-container .MuiChip-root {
	margin: 0.25em;
}

.RecipientChip-popover.MuiPaper-root {
	min-width: 400px;
    margin: 0.5em
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}

@media (max-width: 450px) { 
	.RecipientChip-popover.MuiPaper-root {
		min-width: 84%;
		margin: 0.5em
	}
}
