.CountrySelector-root {
	padding:0.5em;
	display:flex;
	flex-grow:1;
	align-items:center;
}

.CountrySelector-root .MuiFormControl-root {
	display:flex;
	flex-grow:1;
}
.dark .MuiFormLabel-root, .dark .MuiFormLabel-root.Mui-focused {
	color:#FFFFFF!important;
}

.CountrySelector-root.dark .MuiFormControl-root .MuiInputLabel-root.CountrySelector-Select,
.CountrySelector-root.dark .MuiFormControl-root .CountrySelector-Select{
	color:#FFFFFF!important;
}

.MuiPaper-root.CountrySelector-Label.dark {
	color:#FFFFFF!important;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}