.forecast-indicator {
	display:flex;
	flex-direction: row;
	align-items:center;
	justify-content:center;
	flex-grow:1;
}

.forecast-indicator .forecast-compass {
	margin-left:1.5em;
}

.forecast-indicator .forecast-compass .indicator-green {
	fill:#1bc943;
	color:#1bc943;
}

.forecast-indicator .forecast-compass .indicator-blue-alt {
	fill:#11c5db;
	color:#11c5db;
}

.forecast-indicator .forecast-compass .indicator-blue {
	fill:#11c5db;
	color:#11c5db;
}

.forecast-indicator .forecast-compass .indicator-orange {
	fill:rgba(244, 119, 46, 1);
	color:rgba(244, 119, 46, 1);
}

.forecast-indicator .forecast-compass .indicator-red {
	fill:rgba(248, 50, 69, 1);
	color:rgba(248, 50, 69, 1);
}

.title-graph-forecast {
	display:flex;
	align-self:center;
	justify-content:center;
	font-weight:bold;
	margin-bottom: 0.25em;
}