.cycle-state-indicator-root{
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.cycle-state-indicator-root .progress-bar-rounded {
	width:100%;
	max-width:10em;
}