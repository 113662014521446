.payStation-card {
    justify-content: space-between !important;
}

.payStation-card .type-block {
    display: flex;
    flex-direction: row;
    min-width: 25em;
    align-items: center;
}

.payStation-card .type-block .name {
    margin-left: 1em;
    font-size: 1.25em;
    font-weight: bold;
}

.payStation-card .count-block {
    display: flex;
    flex-direction: row;
    min-width: 6em;
}

.payStation-card .count-block.success svg {
    color: rgb(27, 201, 67);
}

.payStation-card .count-block.warning svg {
    color: rgb(244, 119, 46);
}

.payStation-card .count-block svg {
    margin-right: 1em;
}

.locale {
    margin-right: 0.5em;
    display: flex;
}

.locale img {
    min-width: 2em;
}

.payStation-card .count-block.hide-content svg,
.payStation-card .count-block.hide-content div {
    display:none !important;
}

.user-data-item .CountrySelector-root {
    padding-left: 0;
    padding-right: 0;
}



.list-header.payStation-card .general-block .type-block.accountmanager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    margin-left: 3em;
    min-width: 25%;
}

.list-header.payStation-card .count-block {
    text-align: left;
}

.list-header.payStation-card .locale {
    text-align: left;
    margin-left: 3.4em;
}

.list-header.payStation-card .type-block.identity {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    min-width: 25%;
    margin-left: 0;
}