.Administration-root {
	
}

.dark .MuiButtonBase-root:hover span{
	color: black!important;
}

.header.action.edit .MuiTableSortLabel-root,.header.action.delete .MuiTableSortLabel-root { 
	display: none!important;
}

.Administration-root  .header.action.edit { 

}

.user-search-container {
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items:center;
}

.user-search-item {
	display:flex;
	justify-content:center;
	align-items:center;
	margin: 0 1em;
	min-width: 200px;
}

.user-search-item input , .user-search-item .MuiSelect-select {
	padding: 8.5px 14px;
}

.user-search-item-label {
	margin: 0 0.5em;
}

.user-data {
	min-height:5em;
	min-width: 350px;
}

.user-data-item{
	margin: 0.75em 0;
	flex-grow:1;
}

.user-data-item .MuiTextField-root {
	flex-grow:1;
	display:flex;
}

.dark .user-data-item.id {
	background-color:rgba(255,255,255,0.1)
}

.user-data-item.id {
	background-color:rgba(0,0,0,0.1);
	pointer-events: none;
}

.user-search-item  .MuiFormControl-root{
	width:100%;
}

.Root.Dashboard.Alternative .MuiTypography-root,
.Root.Dashboard.Alternative .MuiFormLabel-root,
.Root.Dashboard.Alternative .MuiInputBase-input {
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .MuiOutlinedInput-notchedOutline,
.Root.Dashboard.Alternative .MuiOutlinedInput-notchedOutline,
.dark .MuiOutlinedInput-notchedOutline{
	border-color:rgba( 255 , 255 , 255 , 0.6 )!important;
}

.Root.Dashboard.Alternative .Mui-focused .MuiOutlinedInput-notchedOutline,
.dark .Mui-focused .MuiOutlinedInput-notchedOutline{
	border-color:#FFFFFF!important;
}

.dark span , .dark fieldset, .dark .MuiFormLabel-root, .dark .MuiInputBase-root {
	color:#FFFFFF!important;
}

.dark svg {
	fill:white;
	color:white;
}

.dark h4 {
	color:#FFFFFF!important;
}

@media (max-width: 960px) { 
	
}

@media (max-width: 500px) { 
	.user-search-item button ,.user-search-item  .MuiFormControl-root{
		width:100%;
	}
	
	.user-search-item  .MuiFormControl-root{
		width:100%;
		margin-bottom:0.5em;
	}
}

.MuiTableCell-root.id , .MuiTableCell-root.userid {
	max-width: 5em;
	min-width: 5em;
}

.MuiTableCell-root.userlabel {
	max-width: 15.5em;
	min-width: 15.5em;
}

.MuiTableCell-root.account, .MuiTableCell-root.accountlabel {
	max-width: 12.5em;
	min-width: 12.5em;
}

.MuiTableCell-root.email , .MuiTableCell-root.Email {
	max-width: 20em;
	min-width: 20em;
}

.MuiTableCell-root.phone, .MuiTableCell-root.PhoneNumber {
	max-width: 15.5em;
	min-width: 15.5em;
}

.MuiTableCell-root.access_level {
	max-width: 10em;
	min-width: 10em;
}

.MuiTableCell-root.groups , .MuiTableCell-root.user_groups {
	max-width: 10em;
	min-width: 10em;
}

.MuiTableCell-root.edit {
	max-width: 10em;
	min-width: 10em;
}

.MuiTableCell-root.delete {
	max-width: 10em;
	min-width: 10em;
}

.MuiTableBody-root {
	overflow-x: hidden!important;
}
