.Activations-root {
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
	overflow:hidden;
}

.Activations-root .pie-container {
	max-height:18em!important;
	padding-bottom:2.5em;
}

.Activations-root .pie-container .apexcharts-legend-series {
	text-align: left!important;
}

.Activations-root .content-table {
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
	padding:1.5em 0em;
}

.Activations-root .content-table .column{
	display:flex;
	flex-grow:1;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}

.Activations-root .content-table .column.clickable{
	cursor:pointer;
}

.Activations-root .content-table.graph {
	padding: 0em 3em;
}

.Activations-root .content-table .separator{
	display:flex;
	min-width:0.1em;
	min-height:3.5em;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	background-color: rgba( 0 , 0 , 0 , 0.2 );
}

.Alternative .Activations-root .content-table .separator{
	background-color: rgba( 255 , 255 , 255 , 0.2 );
}

.Activations-root .content-table .column .label{
	display:flex;
	align-items:center;
	justify-content:center;
	opacity:0.75;
	font-size:1.1em;
}

.Activations-root .content-table .column .number{
	display:flex;
	align-items:center;
	justify-content:center;
	font-size:1.75em;
}

.Activations-root .content-table.graph .apexcharts-text.apexcharts-xaxis-label {
	font-size:0.65em;
}

.dialog-content {
	display:flex;
	flex-direction:column;
}

.dialog-content .list-devices {
	padding: 0.75em 0.5em;
}

.dialog-content .list-devices .item {
	display:flex;
	flex-direction:column;
	flex-grow:1;
	padding: 0.75em 0.5em;
	border:solid 1px;
	border-radius:0.5em;
	margin-bottom:0.25em;
}

.dialog-content .list-devices .item .name {
	font-size:1.25em;
	display:flex;
	justify-content:flex-start;
	align-items:flex-start;
}

.dialog-content .list-devices .item .activation-time {
	font-size:0.85em;
	display:flex;
	justify-content:flex-start;
	align-items:flex-start;
}

.MuiPopover-paper.Centered {
	top:5em!important;
	left:55em!important;
}