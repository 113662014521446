.sensor-counter-root{
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	margin-left: 0.5em;
	margin-right: 0.5em;
}

.sensor-counter-root .icon {
	
}

.sensor-counter-root .icon.operational {
	color:#1bc943;
}

.sensor-counter-root .icon.cleanup {
	color:rgba(244, 119, 46, 1);
}

.sensor-counter-root .icon.maintenance {
	color:#f83245;
}

.sensor-counter-root .value {
	
}

