.available-choices {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.mode-button , .selection-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    width: 100%;
}

.dark .mode-button, .dark .selection-button {
    background-color: rgba( 255 , 255 , 255 , 0.1 );
}

.dark .mode-button:hover , .dark .selection-button:hover {
    background-color: rgba( 255 , 255 , 255 , 0.25 );
}

.container-content .selection-button {
    margin-bottom: 0.5em;
    justify-self: flex-start;
}

.container-content .selection-button.selected {
    background-color: #1bc943;
}

.container-content {
    max-height: 30em;
    overflow-y: auto;
    justify-content: flex-start !important;
}

.container-content button {
    display: flex;
    flex-direction: column;
}

.container-content button .extra-info {
    margin-top:0.25em;
    font-style: italic;
    font-size: 0.80em;
    opacity: 0.65;
}
