.Root.Dashboard .MuiTab-wrapper {
	flex-direction:row;
}
.Root.Dashboard .MuiTab-wrapper svg, .Root.Dashboard .MuiTab-root svg{
	margin-right:0.5em;
	margin-top:0.25em;
}

.flex-row {
	display:flex;
	flex-direction:row!important;
}

.MuiTab-root {
	flex-direction:row!important;
}

.MuiTableCell-root.MuiTableCell-head.header.date:not(.ope) {
	flex-grow: 0!important;
	width: 15em;
}

.MuiTableCell-root.MuiTableCell-head.header.cbms {
	flex-grow: 0!important;
	text-align: center!important;
	width: 15em;
}

.MuiTableCell-root.MuiTableCell-body.cell.cbms {
	text-align: left!important;
	padding-left:2em;
}

.MuiTableCell-root.MuiTableCell-head.header.text {
	text-align: center!important;
}

.MuiTableCell-root.MuiTableCell-body.cell.text {
	text-align: left!important;
	padding-left:2em;
}

.cell.date , .header.date {
	min-width: 400px;
	max-width: 400px;
}

.cell.cbms {
	max-width: 265px;
}

.header.terminal ,
.header.cashier,
.cell.terminal ,
.cell.cashier {
	min-width: 400px;
	max-width: 400px;
	justify-content: flex-start;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) {
	.cell.date {
		font-size: 0.8em;
		max-width: 80px;
		padding: 2px;
	}

	.cell.text {
		font-size: 0.9em;
		padding: 2px;
		flex-grow: 1;
		align-items: center;
	}

	.mobile-space-top {
		margin-top: 1em;
	}

	.mobile-space-bottom {
		margin-bottom: 1em!important;
	}

	.cell.terminal, .cell.date , .header.date {
		min-width: 0!important;
	}
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	
}
