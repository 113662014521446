.fullscreen {
	width:100%;
}

.Root.Dashboard {
	display: flex;
	/*padding-bottom: 5em;
	max-height: 100vh;*/
	/*min-height: fit-content;*/
	overflow: hidden;
}

.Root.Dashboard.Alternative {
	background-color: #21252c;/*#424242;*/
	color: white;
}

.Root.Dashboard.Alternative .MuiListItemButtonText-primary,
.Root.Dashboard.Alternative .text-black , 
.Root.Dashboard.Alternative .card-box,
.Root.Dashboard.Alternative .MuiTab-textColorPrimary,
.Root.Dashboard.Alternative .MuiPaper-root,
.Root.Dashboard.Alternative .MuiButtonBase-root,
.Root.Dashboard.Alternative .MuiTableCell-root {
	color:#FFFFFF!important;
}

.dark .MuiTablePagination-menuItem, .Root.Dashboard.Alternative .MuiTablePagination-menuItem {
	background-color: #424242;
	color:#FFFFFF!important;
}

.dark .MuiList-root, .Root.Dashboard.Alternative .MuiPaper-root{
	background-color: #21252c;/*#424242;*/
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .MuiPaper-elevation1 {
	box-shadow: 0 2px 1px -1px hsla(0,0%,100%,.2), 0 1px 1px 0 hsla(0,0%,100%,.14), 0 1px 3px 0 hsla(0,0%,100%,.12);
}

.MuiListItemButton-root.Mui-selected, .MuiListItemButton-root.Mui-selected:hover {
	background-color: rgba(0, 0, 0, 0.08);
}

.Root.Dashboard.Alternative .card-header, .Root.Dashboard.Alternative .card-footer {
	background-color:transparent!important;
	color:#FFFFFF!important;
}

.Root.Dashboard.Alternative .card-header .card-header--title > small {
	opacity:0.6!important;
}

.Root.Dashboard.Alternative .MuiSvgIcon-root {
	color:#FFFFFF!important;
}

/* width */
::-webkit-scrollbar {
	z-index: 10;
  	width: 0.5em!important;
}

.Root.Dashboard.Alternative ::-webkit-scrollbar {
  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;/*#f1f1f1;*/
}

.Root.Dashboard.Alternative ::-webkit-scrollbar-track {
  /*background: #808080/*#f1f1f1;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*background: rgba(122, 123, 151, 0.15);*/
  background: #888;
  border-radius:0.5em;
}

.Root.Dashboard.Alternative ::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /*background: rgb(122, 123, 151);*/
  background: #aaaaaa;
}

.Root.Dashboard.Alternative ::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}

.Root.Dashboard.Alternative .card-box .card-footer, .Root.Dashboard.Alternative .card-box .card-header,
.cm-dialog-error.Alternative .card-box .card-footer, .cm-dialog-error.Alternative .card-box .card-header {
    border-color: rgba(255, 255, 255, 0.125);
}

.Root.Dashboard.Alternative .card-box .card-header,
.cm-dialog-error.Alternative .card-box .card-header {
    background-color: rgba(255, 255, 255, 0.25)!important;
}

.card-box .card-header {
    background-color: rgba(0, 0, 0, 0.125)!important;
}

.MuiAppBar-colorPrimary {
	background-color: #717171!important;
}

.Root.Dashboard.Alternative .MuiAppBar-colorPrimary {
	background-color: #717171!important;
}

.Root.Dashboard .MuiTooltip-popper.MuiTooltip-popperArrow ,.MuiTooltip-popper.MuiTooltip-popperArrow .MuiTooltip-tooltip{
	box-shadow:none !important;
}

.Root.Dashboard .MuiDrawer-paper .MuiListItemIcon-root{
	min-width:40px;
}

.Root.Dashboard .subList{
	background-color:rgba( 0, 0, 0, 0.10);
}

.Root.Dashboard.Alternative .subList{
	background-color:rgba( 255, 255, 255, 0.10);
}

.MuiTableCell-footer {
    font-size: 1.15rem!important;
}

.MuiTableCell-footer .MuiSelect-select {
	font-size: 0.85rem!important;
}

body .MuiTabs-root.nav-tabs-primary .Mui-selected:hover .MuiTab-wrapper {
    color: #fff !important;
}

.last-data-received {
	position:absolute;
	right:3.5em;
	top:0.1em;
	font-size:0.75em
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) { 
	.main-lines {
		margin-top:4px!important;
	}
}


@media (min-width: 1250px) and (max-width: 1750px) and (orientation: landscape) { 
	.Root .MuiButtonBase-root.Icon {
		flex-grow:0;
	}
}

@media (max-width: 400px) { 
	.card-box .card-header .card-header--title > small{
		max-height:16px;
		overflow:hidden;
	}
	
	.card-box .card-header .card-header--title > b.font-size-lg{
		/*max-height:16px;
		overflow:hidden;*/
		font-size:1.05em!important;
	}
	
	.main-lines {
		margin-top:4px!important;
	}
}


.Root.Dashboard.Alternative.Printing .MuiListItemText-primary, 
.Root.Dashboard.Alternative.Printing .text-black, 
.Root.Dashboard.Alternative.Printing .card-box, 
.Root.Dashboard.Alternative.Printing .MuiPaper-root,
.Root.Dashboard.Alternative.Printing .apexcharts-legend-text {
    color: #000000!important;
	background-color: transparent!important;
}

.Alternative.Printing text.apexcharts-text.apexcharts-xaxis-label, 
.Alternative.Printing text.apexcharts-text.apexcharts-yaxis-label {
	fill: #000000!important;
}

.Printing .dark {
	color: #000000!important;
	fill: #000000!important;
}

/* Print styles */
@media print 
{
    tr, td, th {page-break-inside:avoid}
    thead {display:table-header-group}
    .NoPrint {visibility:hidden; display:none}
    a {color:#000000}
	
	#exportable-table , #exportable-graphic {
		
	}
}

#exportable-table-desktop.printing .MuiTableCell-root,
#exportable-table-mobile.printing .MuiTableCell-root{
	background-color:#FFFFFF!important;
	color:#000000!important;
}

#exportable-table-desktop.printing .MuiTableRow-footer .MuiTypography-root ,
#exportable-table-mobile.printing .MuiTableRow-footer .MuiTypography-root ,
#exportable-table-desktop.printing .MuiTableRow-footer .MuiButtonBase-root .MuiSvgIcon-root,
#exportable-table-mobile.printing .MuiTableRow-footer .MuiButtonBase-root .MuiSvgIcon-root{
	background-color:#FFFFFF!important;
	color:#000000!important;
}

#exportable-reminder {
	padding-top:2em;
}

.Printing #exportable {
	padding:2em;
	background-color:#FFFFFF!important;
	color:#000000!important;
}

.Printing #exportable .cm-graph-auto {
	overflow:hidden;
}

body .Root.Dashboard.Printing #exportable .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
	background: #FFFFFF!important;
    box-shadow: none!important;
	font-size:1.25em!important;
	font-weight:bold!important;
	color: black!important;
	text-decoration:underline!important;
}

body .Root.Dashboard.Printing #exportable .MuiTable-root .MuiTableCell-root,
body .Root.Dashboard.Printing #exportable .MuiTable-root .MuiTableCell-root .MuiToolbar-root,
body .Root.Dashboard.Printing #exportable .MuiTypography-root{
	color: black!important;
}

body .Printing #exportable .MuiTabs-root.nav-tabs-primary .MuiTab-root.Mui-selected {
	background-color:#FFFFFF!important;
	font-size:1.25em!important;
	font-weight:bold!important;
	color: black!important;
	text-decoration:underline!important;
}

body .Printing #exportable .MuiTabs-root .MuiTab-root .MuiTouchRipple-root {
	display:none;
	flex-grow: 0;
}

.Root.Dashboard.Printing .MuiTab-wrapper {
	color: black!important;
}

.Root.Dashboard.Printing .MuiTab-root.Mui-selected .MuiTab-wrapper {
	color: black!important;
	font-size:1.25em!important;
	font-weight:bold!important;
	text-decoration:underline!important;
}

.card-box.reminder {
	padding: 0.5em 1em;
	font-size:1.25em;
	font-weight:bold;
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by.MuiTableCell-alignCenter {
	text-align:center!important;
	font-size:1.15em;
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by.MuiTableCell-alignCenter {
	background-color: rgba( 0 , 0 , 0 , 0.15 );
}

.Alternative .MuiTableCell-root.MuiTableCell-body.cell.group-by.MuiTableCell-alignCenter {
	background-color: rgba( 255 , 255 , 255 , 0.15 );
}

.MuiTableRow-root.odd {
	background-color: rgba( 0 , 0 , 0 , 0.02 );
}

.Alternative .MuiTableRow-root.odd {
	background-color: rgba( 255 , 255 , 255 , 0.02 );
}

.sub-total-groupBy {
	display: flex;
	margin-right:0.5em;
	margin-top: 0.5em;
}

.sub-total-groupBy .left-large {
	text-align:left;
	min-width:5em;
}

.sub-total-groupBy .right-price {
	text-align: right;
    min-width: 5em;
    display: inline-block;
}

.group-by .MuiTableCell-root.MuiTableCell-head,
.group-by .MuiTableRow-root .MuiTableCell-root.MuiTableCell-head {
	padding:0;
}

.toggle.icon {
	min-width:0.85em;
	min-height:0.85em;
	max-width:0.85em;
	max-height:0.85em;
	display:inline-block;
	position:relative;
	left:0.5em;
	margin-top:0.75em;
	cursor:pointer;
}

.toggle.icon::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: #000;
  height: 0.20em;
  margin-top: -0.1em;
  top: 50%;
  left: 0.1em;
  right: 0.1em;
  z-index: 9;
}

.toggle.icon::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: #000;
  width: 0.20em;
  margin-left: -0.1em;
  left: 50%;
  top: 0.1em;
  bottom: 0.1em;
  z-index: 9;
}

.toggle.icon.expand {
	
}

.toggle.icon.expand::before {
	display:none;
}

.Alternative .toggle.icon::after, .Alternative .toggle.icon::before {
	background-color: #fff;
}

.text-disabled {
    color: #888 !important;
}

.bg-disabled {
    background-color: #888 !important;
}

.shadow-disabled {
    background-color: #888 !important;
}

.custom-label {
	font-size:1.25em;
	margin:0.75em 0;
}

@media (min-width: 1930px) { 
	.MuiContainer-maxWidthXl {
		max-width:2560px;
	}
}

.Snackbar_snackbar-wrapper__ocbPJ {
	z-index:2000;
	margin-top:5em
}

.toast {
	display:flex;
	flex-grow:1;
}

.toast .Icon{
	display:flex;
	margin-right:0.75em;
}

.toast .message{
	display:flex;
	flex-grow:1;
	align-items:center;
	justify-content:center;
}

.success .Icon{
	color:#1bc943;
}

.warning .Icon{
	color:rgb(244, 119, 46);
}

.info .Icon{
	color:#11c5db;
}

.error .Icon{
	color:rgb(248, 50, 69);
}

.cm-password-strength-bar{
	
}

.clickable {
	cursor:pointer !important;
}

.fullscreen {
	width:100%;
}


.MuiTab-root.Mui-selected {
	/*background-color:red;
	border-radius: 0.29rem !important;*/
}

body .Root.Dashboard.Alternative .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
	background: #717171!important;
	box-shadow: 0 16px 26px -10px rgba(255, 255, 255, 0.16), 0 4px 25px 0 rgba(0, 0, 0, 0.42), 0 8px 10px -5px rgba(255, 255, 255, 0.2);
}

body .Root.Dashboard .MuiTabs-root.nav-tabs-primary .MuiTabs-indicator {
	background: #717171!important;
	box-shadow: 0 16px 26px -10px rgba(0, 0, 0, 0.56), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

body .Root.Dashboard.Alternative .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover {
	color: #FFFFFF!important;
	opacity:1;
}

body .Root.Dashboard .MuiTabs-root.nav-tabs-primary .MuiTab-root:hover {
	color: #717171!important;
	opacity:1;
}

.tab-panel-content {
	padding-top:1em;
}

.hidden {
	display:none!important;
}

#exportable-reminder .MuiGrid-item {
	padding: 4px;
}

.card-box.reminder.operation-reminder {
	display:flex;
	flex-direction:column;
	justify-content:center;
	padding : 0 0;
}

.card-box.reminder.operation-reminder .type-reminder {
	align-self: start;
	text-align:center;
	flex-grow:1;
	padding : 0.5em 1em;
	min-width:100%;
	width:100%;
	max-width:100%;
	font-size:0.9em;
}

.card-box.reminder.operation-reminder .total-reminder-content {
	display:flex;
	flex-direction:row;
	padding : 0.5em 1em;
	flex-grow:1;
	justify-content:center;
}

.card-box.reminder.operation-reminder .type-reminder {
	background-color:rgba( 0 , 0 , 0 , 0.15);
}

.Alternative .card-box.reminder.operation-reminder .type-reminder {
	background-color:rgba( 255 , 255 , 255 , 0.15);
}

.MuiTableCell-root.MuiTableCell-head.header.error, .MuiTableCell-root.MuiTableCell-head.header.manual {
	min-width:6vh;
	width:6vh;
	max-width:6vh;
}

.MuiTableCell-root.MuiTableCell-head.header.date {
	text-align: right!important;
	/*padding-left: 4em;*/
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .type-label {
	text-align: left;
	padding-left: 5em;
}

.MuiTableCell-root.MuiTableCell-body.cell.group-by .opt-data-label {
	text-align: left;
	padding-left: 8em;
}

.cm-dialog-error .MuiPaper-rounded {
	border-radius: 0.75em;
}

.error-line-indicator {
	cursor:pointer;
}

.scrollable {
	overflow-y: auto;
}

.material-container {
	display: flex;
	flex-grow: 1;
	border: solid 1px;
	border-radius: 0.25em;
	width: 100%;
	margin-bottom: 1em;
	flex-direction: column;
	min-height: 2em;
}

.dark .material-container {
	border-color: rgba( 255 , 255 , 255 , 0.6 );
}

.material-container .container-title {
	display: flex;
	align-self: flex-start;
	justify-self: flex-start;
	font-size: 0.75em;
	margin-top: -1.25em;
	margin-left: 0.5em;
	padding: 0.5em;
	background-color: #FFFFFF;
}

.dark .material-container .container-title {
	background-color: #21252c;
}

.material-container .container-content {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0.75em;
	flex-direction: column;
}
