.users-block {
    display: flex;
    flex-direction: row;
}

.users-block .count {
    margin-left: 0.5em;
}

.selection-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4em;
    min-width: 5em;
}

.creator-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 4em;
    margin-right: 2.5em;
    align-items: flex-end;
}

.financial-block,
.technical-block ,
.users-block ,
.creator-block ,
.created-block {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.financial-block svg.enabled ,
.technical-block svg.enabled  {
    color: #1bc943;
}

.financial-block svg,
.technical-block svg {
    color: #f83245;
}

.mode-selector-groups {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.mode-selector-groups .selection-button {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.mode-selector-groups .selection-button.selected {
    background-color: #1bc943;
}


.list-header.payStation-card .general-block .type-block.groupmanager {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    margin-left: 0.8em;
    min-width: 25%;
}

.list-header.payStation-card .general-block .selection-block.title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
}

.list-header.payStation-card .general-block .creator-block.title {
    padding-right: 5.4em;
}

.list-header.payStation-card .creator-block {
    text-align: left;
    margin-left: 3em;
}

.list-header.payStation-card .created-block {
    padding-right: 9em;
}