.dark .MuiPaper-root,
.Alternative .header-admin {
    color: #d5dbdb;
    background-color: #21252c;
}

.header-admin {
    padding: 0.75em;
}

.header-admin .action-bar {
    display: flex;
    flex-direction: row;
}

.header-admin .action-bar .title {
    padding: 0.25em;
    font-size: 1.25em;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.Alternative .header-admin .action-bar .title {
    color: white;
}

.header-admin .action-bar .count {
    padding: 0.25em;
    font-size: 1.15em;
    align-items: center;
    justify-content: center;
}

.Alternative .header-admin .action-bar .count {
    color: #95a5a6;
}

.header-admin .action-bar .spacer {
    display: flex;
    flex-grow: 1;
}

.header-admin .action-bar .actions {
    display: flex;
    padding: 0.25em;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.header-admin .action-bar .actions .button {
    padding: 0.25em 0.75em;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba( 0 , 0 , 0 , 0.55 );
    color: rgba( 0 , 0 , 0 , 0.85 );
    font-weight: bold;
    border-radius: 0.1em;
    font-size: 0.95em;
    margin: 0.25em;
    cursor: pointer;
}

.Root.Dashboard.Alternative .header-admin .action-bar .actions .button {
    border: 1px solid rgba( 255 , 255 , 255 , 0.55 );
    color: rgba( 255 , 255 , 255 , 0.85 );
}

.header-admin .action-bar .actions .button.disabled {
    border: 1px solid rgba( 0 , 0 , 0 , 0.25 );
    color: rgba( 0 , 0 , 0 , 0.45 );
    cursor: default;
}

.Root.Dashboard.Alternative .header-admin .action-bar .actions .button.disabled {
    border: 1px solid rgba( 255 , 255 , 255 , 0.25 );
    color: rgba( 255 , 255 , 255 , 0.45 );
}

.header-admin .filter-bar {
    display: flex;
    flex-grow: 1;
    margin-top: 0.5em;
    justify-content: center;
    align-items: center;
}

.header-admin .filter-bar .search-field-expand {
    display: flex;
    flex-grow: 1;
}

.header-admin .filter-bar .search-field-medium {
    display: flex;
    width: 20em;
}

.header-admin .filter-bar .spacer {
    width: 0.5em;
}

.Alternative .header-admin .filter-bar .Mui-selected {
    background-color: rgba( 255 , 255 , 255 , 0.25 );
}

.MuiList-root {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    max-height: 40em;
    overflow-y: auto;
}

.payStation-card {
    display: flex;
    flex-direction: row;
}

.Alternative .payStation-card {
    background-color: #2a2e33;
}

.payStation-card.selected {
    color: #FFFFFF;
    border: solid 1px #00a1c9;
    background-color: #12293b;
}

.payStation-card .identification {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 20%;
}

.payStation-card .identification .reseller-block,
.payStation-card .identification .customer-block {
    display: flex;
    flex-direction: row;
}

.payStation-card .general-block {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
}

.payStation-card .identification .reseller-block svg,
.payStation-card .identification .customer-block svg  {
    font-size: 1.25em;
}

.payStation-card .general-block svg  {
    font-size: 1.5em;
}

.payStation-card .general-block .navigation-block svg {
    font-size: 1.25em;
}

.payStation-card .identification .reseller-block .reseller-name,
.payStation-card .identification .customer-block .customer-name  {
    display: flex;
    justify-content: center;
    font-size: 1.15em;
    margin-right: 0.75em;
    margin-left: 0.75em;
}

.payStation-card .general-block .box-name {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 0.75em;
    margin-left: 0.75em;
    min-width: 30%;
    max-width: 30%;
}

.payStation-card .general-block .box-name .main{
    display: flex;
    justify-content: flex-start;
    font-size: 1.15em;
}

.payStation-card .general-block .box-name .extra {
    display: flex;
    justify-content: flex-start;
    font-size: 0.80em;
    color: #95a5a6;
}

.payStation-card .general-block .software-block {
    padding-right: 1.5em;
    min-width: 25%;
    text-align: left;
}

.payStation-card .general-block .software-block .name {

}

.payStation-card .general-block .software-block .properties {
    display: flex;
    flex-direction: row;
}

.payStation-card .general-block .software-block .properties .version {
    margin-right: 1em;
    color: #95a5a6;
    font-size: 1.1em;
    display: flex;
    align-items: flex-end;
}

.payStation-card .general-block .software-block .properties .type {
    color: #95a5a6;
    font-size: 0.9em;
    display: flex;
    align-items: flex-end;
}

.payStation-card .general-block .device-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 25%;
}

.payStation-card .general-block .device-block .device-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.payStation-card .general-block .device-block .device-item .type{
    margin-left: 1em;
}

.large-button {
    width: 100%;
}

.dark .large-button:hover {
    background-color: rgba( 255 , 255 , 255 , 0.1 );
}

.item-expandable {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.item-expandable .main {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item-expandable .expandable {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 15em;
    overflow: hidden;
    transition: max-height 0.5s;
}

.item-expandable .expandable.collapsed {
    max-height: 0;
}

.item-expandable .expandable .terminal-line,
.item-expandable .expandable .device-line {
    display: flex;
    flex-direction: row;
}

.item-expandable .expandable .terminal-name {
    /*margin-right: 0.5em;*/
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20em;
}

.item-expandable .expandable .device-name {
    /*margin-right: 0.5em;*/
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: start;
    min-width: 13em;
    margin-left: 7em;
}

.item-expandable .expandable .terminal-spacer {
    margin: 0 1.5em
}

.item-expandable .expandable .device-spacer {
    margin: 0 0.25em
}

.item-expandable .expandable .terminal-license,
.item-expandable .expandable .device-serial {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20em;
}

.spacer.top {
    margin: 0.75em 0;
    border-bottom: solid 1px white;
    width: 100%;
    opacity: 0.5;
}

.spacer.section {
    margin: 0.5em 0;
    border-bottom: solid 1px white;
    width: 100%;
    opacity: 0.35;
}

.list-header.payStation-card {
    justify-content: flex-start !important;
    display: flex;
    width: 100%;
    flex-grow: 1;
    padding: 16px 4px;
    margin-bottom: 0.05em;
    border-bottom: 1px solid rgba(224, 224, 224, 0.65);
}

.list-header.payStation-card .general-block {
    justify-content: flex-start;
}

.list-header.payStation-card .general-block .device-block {
    justify-content: flex-start;
    min-width: 25%;
    text-align: left;
}

.list-header.payStation-card .general-block .box-name {
    flex-direction: column;
    align-items: flex-start;
    min-width: 30%;
}

.list-header.payStation-card .general-block .icon-spacer {
    width: 5%;
}

.list-header.payStation-card .identification {
    text-align: left;
}