#installation-state-monitoring .payStation-card .general-block {
    justify-content: flex-start;
    flex-grow: 1;
}

#installation-state-monitoring .payStation-card .general-block .box-name {
    max-width: 38em;
}

#installation-state-monitoring .payStation-card .last-connection-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 14em;
    font-size: 0.9em;
}

#installation-state-monitoring .payStation-card .spacer {
    display: flex;
    flex-grow: 1;
}

#installation-state-monitoring .payStation-card .spacer-fixed {
    display: flex;
    width: 2.5em;
}

#installation-state-monitoring .payStation-card .state-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;
}

.not-installed{
    color:#424242;
}

.online svg{
    color:#1bc943;
}

.offline svg{
    color:#f83245;
}

.partially svg{
    color:rgba(244, 119, 46, 1);
}

.stopped svg{
    color:#11c5db;
}

.maintenance-required svg{
    color:rgb(17, 197, 219);
}

.under-maintenance svg{
    color:purple;
}

.button-filter-state {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    font-size: 1.25em;
    flex-direction: column;
    margin-left: 0.25em;
    margin-right: 0.25em;
    padding: 0.25em;
    cursor: pointer;
    margin-top: -1em;
}

.button-filter-state.selected {
    border: dashed 1px rgba( 255 , 255 , 255 , 0.75 );
}

.button-filter-state .state.label{
    font-size: 0.5em;
}
