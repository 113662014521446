.group-data {
    min-height:5em;
    min-width: 350px;
}

#group-dialog-title {
    justify-content: center;
    align-items: center;
    display: flex;
}


.material-container {
    margin-top: 1.5em;
}

.data-type-line {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}


.selection-button {
    min-width: 4em;
}

.selector {
    min-width: 4em;
}

.MuiDialog-container .MuiPaper-root .MuiDialogContent-root {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 20em;
    min-width: 20em;
}
