.selector-container {
	display:flex;
	align-items:flex-start;
	margin-top:1.5em;
	/*margin-bottom:1.5em;*/
	margin-left:1.5em;
}

.select-box-selector, .select-box-selector{
	min-width:25em!important;
	margin:0.5em;
	/*max-width:25em!important;*/
}

.dark .text-black-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.dark .MuiMenuItem-root {
	color:#FFFFFF;
}

.box-select-line {
	display:flex;
	flex-direction: column;
	flex-grow: 1;
}

#combo-box-selector-select {
	display:flex;
	flex-direction: row;
	flex-grow: 1;
}

.box-select-icon {
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.box-select-line .main {
	display:flex;
	flex-direction: row;
	flex-grow: 1;
}

.box-select-line .sub {
	display:flex;
	flex-direction: row;
	flex-grow: 1;
	font-style: italic;
	font-size: 0.90em;
	opacity: 0.65;
}

/* 960Px => UI-Material breakpoint from small to medium */
@media (max-width: 960px) {
	.select-box-selector {
		min-width: 15em !important;
	}

	#combo-box-selector-select {
		max-width: 220px !important;
		width: 220px !important;
		min-width: 220px !important;
	}

	.box-select-line {
		max-width: 180px !important;
		width: 180px !important;
		min-width: 180px !important;
		overflow: hidden;
	}
}

